@import 'styles/settings.scss';

.c-dropdown {
  width: 100%;

  font-weight: $fw-semi-bold;
  font-size: $fs-xsmall;
  line-height: normal;
  color: $color-gray-7;
  position: relative;

  &__selected-option {
    width: 100%;
    padding: $spacing-4xs $spacing-s;
    border: 1px solid $color-gray-9;
    border-radius: $radius-xs;
    font-size: $fs-xsmall;

    min-height: 2.063rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-2xs;
    position: relative;

    cursor: pointer;

    &--readonly {
      //Comment left in case we need this elsewhere
      border-color: transparent;
      cursor: default;
      pointer-events: none;
    }

    &--opened {
      background-color: $color-gray-5;
      border-color: $color-gray-5;

      path {
        fill: $color-secondary;
      }
    }

    &:hover {
      path {
        fill: $color-secondary;
      }
    }

    &--invalid {
      outline: 1px solid $color-red;
    }

    &--right-align {
      gap: $spacing-2xs;
      justify-content: flex-end;
      text-align: right;
    }

    &--missing {
      justify-content: flex-end;
      min-height: 2.063rem;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &--xs {
      min-height: $spacing-s;
      padding: $spacing-4xs $spacing-2xs;
    }

    &--small {
      padding: $spacing-4xs $spacing-3xs;

      label {
        font-size: $fs-xsmall;
        font-weight: $fw-semi-bold;
        color: $color-dark-gray;
      }
    }

    &--medium {
      padding: $spacing-3xs $spacing-s;
      min-height: 33px;

      label {
        font-size: $fs-small;
        font-weight: $fw-semi-bold;
        color: $color-dark-gray;
      }
    }

    &--large {
      padding: $spacing-xs $spacing-l;
      font-size: $fs-small;

      border-color: $color-light-gray-new-3;
    }

    &--error {
      border-color: $color-error;
    }
  }

  &__selected-option-value {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-gray-7;

    cursor: pointer;

    &::first-letter {
      text-transform: uppercase;
    }

    &--xs {
      font-size: $fs-xxsmall;
      font-weight: $fw-semi-bold;
    }

    &--large {
      font-size: $fs-small;
      font-weight: $fw-semi-bold;
      color: $color-dark-gray;
    }
  }

  &__placeholder {
    color: $color-light-gray-new-3;
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
  }

  &__icon {
    height: 16px;
    width: 16px;
    transition: $transition-1;

    &--opened {
      transform: rotate(180deg);
    }

    &--input-included {
      position: absolute;
      top: auto;
      right: $spacing-s;
    }

    &--xs {
      height: 12px;
      width: 12px;
    }
  }

  &__error {
    color: $color-error;
    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;
    margin-top: $spacing-2xs;
  }

  &__options {
    width: 100%;
    margin-top: $spacing-2xs;
    padding: $spacing-2xs $spacing-5xs;
    height: fit-content;
    max-height: 300px;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    position: absolute;

    background-color: $color-white;
    box-shadow: $box-shadow-1;
    border-radius: $radius-s;
    z-index: 2;

    &--overflow {
      bottom: 100%;
    }
  }

  &__option {
    padding: $spacing-2xs;
    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;

    cursor: pointer;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      background-color: $color-gray-5;
      border-radius: 15px;
    }
  }

  &__search {
    padding: $spacing-2xs;
    margin-bottom: $spacing-5xs;

    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;

    background-color: $color-gray-5;
    border-radius: $radius-s;
  }

  &__no-content {
    padding: $spacing-2xs 0;
    pointer-events: auto;
    cursor: default;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__dropdown-input-field {
    height: 100%;
    width: 100%;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-gray-7;

    outline: none;
    border: none;

    position: absolute;
    left: 0;
    padding: 0 calc($spacing-xl + 16px) 0 $spacing-s;

    &:focus {
      outline: none;
      background-color: transparent;
      border-color: transparent;
    }

    &--opened {
      background-color: $color-gray-5;
      border-color: $color-gray-5;
    }

    &--right-align {
      text-align: right;
    }
  }
}

.c-drop-down-option {
  padding: $spacing-xs;
  font-weight: $fw-semi-bold;
  background-color: $color-white;
  border-radius: $radius-xs;

  font-size: $fs-xsmall;
  color: $color-gray-7;

  cursor: pointer;

  &::first-letter {
    text-transform: uppercase;
  }

  &--selected {
    background-color: $color-gray-5;
  }

  &--year {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-4xs;

    border: 1px solid $color-gray-8;
    border-radius: $radius-xs;

    cursor: default;
  }

  &:hover {
    background-color: $color-gray-5;
  }
}

.c-drop-down-options {
  width: 100%;
  background-color: $color-white;

  padding: $spacing-2xs $spacing-xs;

  display: flex;
  flex-direction: column;
  position: absolute;

  background-color: $color-white;
  box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.16);
  border-radius: $spacing-2xs;

  top: 110%;
  left: 0;

  &__arrow-icon {
    &:hover {
      path {
        fill: $color-secondary;
      }
    }
  }
}
