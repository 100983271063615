@import 'styles/settings.scss';

.c-filter-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-left: $spacing-l;

  &__title {
    margin-bottom: $spacing-2xs;
    justify-content: center;
    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new;
    padding-left: $spacing-2xs;
  }

  &__filters-container {
    padding-right: $spacing-l;
    display: flex;
    flex-direction: column;
    gap: 0.15rem;

    max-height: calc(5 * $spacing-2xl);

    overflow-y: auto;
    @include custom-scrollbar;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: $spacing-4xs;
    font-size: $fs-xsmall;
    width: max-content;
    padding: $spacing-4xs $spacing-2xs;
    background-color: transparent;

    &--default {
      color: $color-dark-gray;
      font-weight: $fw-base;
    }

    &--selected,
    &:hover {
      cursor: pointer;

      color: #eda439;
      font-weight: $fw-semi-bold;
      background-color: #f6e2c6;
      border-radius: $radius-s;
    }
  }
}
