@import 'styles/settings.scss';

.c-week-days-selection {
  display: flex;
  justify-content: flex-end;
  gap: $spacing-l;

  &__option {
    height: 25px;
    width: 25px;
    padding: $spacing-5xs;

    font-size: $fs-xsmall;
    color: $color-gray;
    font-weight: $fw-semi-bold;
    text-align: center;

    border-radius: 50%;
    border: 1px solid $color-gray;
    cursor: pointer;

    &--selected {
      border-color: $color-gray-7;
      color: $color-gray-7;

      &:not(.c-week-days-selection__option--unclickable) {
        border-color: $color-primary;
      }
    }

    &--unclickable {
      &:hover {
        cursor: default;
      }
    }
  }
}
