@import 'styles/settings.scss';

.c-sidebar {
  width: 100%;
  padding: $spacing-xs $spacing-s;

  display: flex;
  flex-direction: column;
  gap: 2px;
}
