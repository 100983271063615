@import 'styles/settings.scss';

.c-people-popup {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__buttons {
    margin-bottom: $spacing-xl;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-xl;

    @media (max-width: 1072px) {
      flex-direction: column;
      justify-content: center;
      gap: $spacing-5xs;
    }
  }

  &__button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-xl 0;
  }

  &__or {
    color: $color-gray;
  }

  &__form {
    margin: $spacing-xl 0 $spacing-3xl;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-l;
  }

  &__bottom-container {
    margin: $spacing-xs auto;
  }

  &__multiple-inputs {
    width: 100%;
    display: flex;
    align-items: center;

    gap: $spacing-l;
    flex-direction: row;
  }

  &__optional-text {
    font-weight: 100;
  }
}
