@import 'styles/settings.scss';

.c-user-workflow-section {
  display: flex;
  flex-direction: column;
  padding: $spacing-l $spacing-xl;
  background-color: $color-white;
  border-radius: $spacing-l;

  &__header {
    display: grid;
    grid-template-columns: 49% 16.6% 0 30%;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-2xs;
    padding: $spacing-2xs 0;

    position: relative;
  }

  &__title {
    flex: 1;

    display: flex;
    align-items: center;
  }

  &__title-input {
    width: 100%;
  }

  &__edit {
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content-main {
    border-top: 1px solid $color-gray-7;
    border-bottom: 1px solid $color-gray-7;
  }

  &__icon {
    cursor: grab;
    visibility: hidden;

    &--hovered {
      visibility: visible;
    }

    rect {
      stroke: $color-gray-2;
    }
  }

  &__new-task-holder {
    padding: $spacing-l;
  }

  &__new-task-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  &__column-header {
    font-size: $fs-xsmall;
  }

  &__new-task-input-holder {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;

    padding: 0 $spacing-4xl;
    width: 100%;

    input {
      height: 40px;
      width: 100%;

      padding-left: $spacing-2xs;

      outline: none;
      border: 0.5px solid $color-blue;
      background-color: $color-light-blue;

      font-family: $font-family;
      font-size: $fs-base;
      color: $color-dark-gray;
    }
  }
}
