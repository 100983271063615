@import 'styles/settings.scss';

.c-addmember-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;

  &__input-label {
    padding-bottom: $spacing-2xs;
    color: $color-dark-gray-new-2;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
  }

  &__field-holder {
    min-height: 2.063rem;
    position: relative;
  }

  &__input-field {
    width: 100%;
    min-height: 2.063rem;
    display: flex;
    align-items: center;

    padding: $spacing-4xs $spacing-2xs $spacing-4xs $spacing-s;

    border-radius: $radius-xs;

    position: absolute;

    color: $color-dark-gray-new-2;
    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;

    &:focus {
      outline: none;
      background-color: $color-gray-5;
      border-color: $color-gray-5;
    }

    &--border {
      border: 1px solid $color-gray-9;
    }

    &--invalid {
      outline: 1px solid $color-red;
    }

    &:not(.c-addmember-dropdown__input-field--readOnly) {
      cursor: pointer;
    }
  }

  &__icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
    transition: $transition-1;

    position: absolute;
    right: $spacing-s;
    top: 50%;
    transform: translateY(-50%);

    &--rotated {
      transform: rotate(180deg) translateY(50%);
      transition: transform 0.2s;

      path {
        fill: $color-secondary;
      }
    }
  }

  &__required-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    position: absolute;
    bottom: $spacing-l;

    width: 110px;

    padding: 2px $spacing-2xs;
    margin-left: $spacing-xl;

    color: $color-white;
    font-size: $fs-xsmall;

    border-radius: 5px;
    background-color: $color-dark-gray;
  }

  &__options-wrap {
    width: 100%;
    position: absolute;
    top: 100%;
    z-index: 1;

    margin-top: $spacing-2xs;
    padding: $spacing-2xs $spacing-5xs;

    background-color: $color-white;
    box-shadow: $box-shadow-1;
    border-radius: $spacing-l;

    &--overflow-with-label {
      top: unset;
      bottom: 75%;
    }
  }

  &__options {
    max-height: 220px;
    display: flex;
    flex-direction: column;

    overflow: auto;
    @include custom-scrollbar;

    width: 100%;
    z-index: 1;
  }

  &__option {
    display: flex;
    align-items: center;

    padding: $spacing-2xs;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-5;
      border-radius: 15px;
    }

    &--missing {
      padding: $spacing-2xs;
      color: $color-light-gray-new-3;
    }
  }

  &__option-value {
    color: $color-dark-gray !important;
    font-size: $fs-xsmall !important;
    font-weight: $fw-semi-bold !important;
  }

  &__option-value-subtitle {
    font-weight: $fw-base !important;
    color: $color-light-gray-new-3 !important;
    font-size: $fs-xxsmall !important;
  }

  &__error-message {
    color: $color-error;
    margin-top: $spacing-2xs;
    font-size: $fs-xxsmall;
  }

  ::placeholder {
    font-size: $fs-small;
    color: $color-gray-2;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }
}
