@import 'styles/settings.scss';

.c-form {
  height: 100vh;
  position: relative;

  overflow-y: scroll;

  display: flex;
  flex-direction: row-reverse;

  &__content-container {
    position: fixed;
    left: 0;
    right: 1.25rem;
    top: 0;
    bottom: 0;

    background-color: $color-light-gray-new-8;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;

    @include breakpoint(phone) {
      display: none;
    }
  }

  &__content-container-right {
    width: 50%;
    z-index: 2;

    @include breakpoint(phone) {
      width: 100%;
    }
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacing-4xl 0;

    background: linear-gradient(163deg, #6727e2 2.95%, #230361 109.87%);
    min-height: 100%;
  }
}
