@import 'styles/settings.scss';

.c-task-filters {
  display: flex;
  gap: $spacing-xs;

  background-color: $color-white;
  padding: $spacing-xl;
  border-radius: $radius-s $radius-s 0 0;
}
