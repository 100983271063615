@import 'styles/settings.scss';

.c-notification {
  &__title {
    display: inline-block;
    font-family: $font-family !important;
    font-weight: $fw-bold-light !important;
    font-size: $fs-small !important;
    color: $color-gray-7 !important;
    text-transform: uppercase;
  }

  &__message {
    font-family: $font-family !important;
    font-size: $fs-xsmall !important;
    color: $color-gray-3 !important;
    font-weight: $fw-semi-bold !important;
  }

  &__top-container {
    display: flex;
    gap: $spacing-xs;
    align-items: center;
    margin-bottom: $spacing-xs;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  &__warning {
    path {
      fill: $color-secondary;
    }
  }

  &--success {
    background-color: $color-white !important;
    border: none !important;
    border-radius: $radius-s !important;
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.16) !important;
  }

  &--info {
    background-color: $color-white !important;
    border: none !important;
    border-radius: $radius-s !important;
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.16) !important;
  }

  &--warning {
    background-color: $color-white !important;
    border: none !important;
    border-radius: $radius-s !important;
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.16) !important;
  }

  &--error {
    background-color: $color-white !important;
    border: none !important;
    border-radius: $radius-s !important;
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.16) !important;
  }

  &__close-button {
    display: none !important;
  }
}
