@import 'styles/settings.scss';

.c-timesheet-cell {
  position: relative;
  vertical-align: middle;

  min-height: calc(2.063rem + $spacing-l);

  font-size: $fs-small;
  font-weight: $fw-medium;
  color: $color-dark-gray-new-2;

  border: 0.5px solid $color-light-gray-new-2;

  &:not(.c-timesheet-cell--dropdown) {
    padding: $spacing-2xs;
  }

  &--last-day {
    &:first-child {
      border-bottom-left-radius: $radius-s;
    }
    &:last-child {
      border-bottom-right-radius: $radius-s;
    }
  }

  &__input {
    width: 100%;
  }
}
