@import 'styles/settings.scss';

.c-insights {
  height: 100%;

  padding: $spacing-l 0 $spacing-l $spacing-xl;

  overflow-y: auto;
  @include custom-scrollbar;

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-xl;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  &__chart-holder {
    display: flex;
    padding: $spacing-l;
    align-items: center;
    justify-content: center;
    width: calc(50% - $spacing-xl / 2);
    background-color: $color-white;
    border-radius: $radius-s;
    min-height: 360px;
  }
}
