@import 'styles/settings.scss';

.c-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  background-color: $color-white;
  height: $toolbar-height;
  padding: 0 $spacing-xl;

  &__item {
    flex: 1;
    display: flex;
    align-items: center;

    &--right {
      justify-content: flex-end;
      gap: 0 $spacing-2xl;
    }

    &--left {
      justify-content: flex-start;
      gap: 0 $spacing-2xl;
    }
  }

  &__title-container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: $spacing-4xs;
    line-height: 1.688rem;
  }

  &__title-item {
    color: $color-black;
    font-size: $fs-xlarge-plus;
    font-weight: $fw-extra-bold;
    position: relative;

    &:not(:first-child) {
      margin-left: 10px;

      &::before {
        content: '';
        display: inline-block;
        height: $spacing-2xl;
        width: $spacing-5xs;
        background-color: $color-black;
        margin-right: $spacing-s;
        border-radius: $radius-xxs;
      }
    }
  }

  &__subtitle {
    min-height: $spacing-l;
    margin-top: $spacing-2xs;
    font-weight: $fw-semi-bold;
    font-size: $fs-xsmall;
    line-height: $fs-base-l;
    color: $color-gray-7;
  }

  &__search-field {
    position: relative;
  }

  &__search-input {
    height: 40px;
    min-width: 320px;
    padding: $spacing-2xs $spacing-4xl;

    color: $color-dark-gray;
    font-size: $fs-xsmall;
    font-weight: $fw-medium;

    border: 1px solid $color-light-gray-new-2;
    border-radius: $spacing-l;
    background-color: $color-white;

    &:focus {
      outline: 1px solid $color-light-gray-new-2;
    }
  }

  &__search-field-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  &__search-field-icon-close {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    max-width: 17px;

    &:hover {
      cursor: pointer;
    }
  }
}
