@import 'styles/settings.scss';

.c-roles-section {
  &__missing-content-container {
    height: 108px;
    padding-top: $spacing-5xs;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__missing-content-message {
    font-size: $fs-small;
    color: $color-gray-3;
  }
}
