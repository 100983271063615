@import 'styles/settings.scss';

.c-export-data {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $spacing-l $spacing-xl;

  background-color: $color-white;
  border-radius: $radius-s;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: $spacing-4xs;
  }

  &__title {
    color: $color-gray-7;
    font-weight: $fw-bold;
    font-size: $fs-base;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--missing {
      padding: $spacing-l 0;
      color: $color-gray-3;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 14px 0;

    border-bottom: 1px solid $color-gray-5;
  }

  &__item-name {
    display: flex;
    align-items: center;

    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-gray-7;

    &:hover {
      cursor: pointer;
      color: #b08dd2;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-3xs;
  }

  &__export-icon {
    path {
      &:not(:last-child) {
        fill: $color-light-gray-new-3;
      }

      &:last-child {
        stroke: $color-light-gray-new-3;
      }
    }

    &:hover {
      cursor: pointer;
      path {
        &:not(:last-child) {
          fill: $color-dark-gray-new-2;
        }

        &:last-child {
          stroke: $color-primary;
        }
      }
    }
  }
}
