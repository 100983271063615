@import 'styles/settings.scss';

.c-task-table {
  height: 100%;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  padding: $spacing-l 0 $spacing-l $spacing-xl;

  border-radius: $spacing-2xs;
  background-color: $color-white;

  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: $spacing-xl;

    font-size: $fs-medium;
    font-weight: $fw-bold-light;
    color: $color-dark-gray;
  }

  &__options {
    font-size: $fs-medium;
    font-weight: $fw-base;
    color: $color-gray-2;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
    }
  }

  &__cancel {
    color: $color-red;

    &:hover {
      cursor: pointer;
      color: $color-red;
    }
  }

  &__save {
    &:hover {
      cursor: pointer;
      color: $color-blue;
    }
  }

  &__edit-options {
    display: flex;
    gap: $spacing-l;
  }

  &__content {
    display: grid;
    grid-auto-rows: 1fr;

    overflow: auto;
    @include custom-scrollbar;

    &--missing {
      flex: 1;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;

    overflow: auto;
  }

  &__content-header {
    display: grid;
    grid-template-columns: 30% repeat(2, 15%) repeat(4, 10%);

    font-size: $fs-base;
    color: $color-dark-gray;
    font-weight: $fw-semi-bold;

    margin-right: $spacing-xl;
    padding-bottom: $spacing-l;
    border-bottom: 1px solid $color-dark-gray-new-2;
  }

  &__content-header-item {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;

    &:first-child {
      font-size: $fs-base;
      font-weight: $fw-bold;
    }

    &:last-child,
    &:nth-last-child(2) {
      justify-self: center;
    }
  }
}
