@import 'styles/settings.scss';

.c-document-upload-modal {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: $spacing-l;

  &__dropdown-label {
    display: inline-block;
    padding-bottom: 0.5rem;

    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;
  }
}
