@import 'styles/settings.scss';

.c-empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-xs;
  height: 100%;

  &__title {
    color: $color-dark-gray-new-2;
    text-align: center;
    font-size: $fs-large;
    font-weight: $fw-bold;
  }

  &__info {
    color: $color-gray-2;
    text-align: center;
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    margin-bottom: $spacing-xs;
    line-height: normal;
    max-width: 40%;
  }
}
