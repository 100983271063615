@import 'styles/settings.scss';

.c-create-workflow-modal {
  width: 100%;

  &__form-wrapper {
    margin: $spacing-l 0 $spacing-xl 0;
  }

  &__dropdown-label {
    display: block;
    margin-bottom: $spacing-2xs;

    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;
  }

  &__error-message {
    display: block;
    margin: $spacing-2xs 0;
    height: $fs-xsmall;
    color: $color-red;
    font-size: $fs-xsmall;
  }

  &__btn-container {
    max-width: 250px;
    margin: 0 auto;
  }
}
