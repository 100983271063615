@import 'styles/settings.scss';

.c-divider {
  width: 100%;
  height: 1px;

  &--light {
    background-color: $color-gray-5;
  }

  &--medium {
    background-color: $color-gray;
  }

  &--dark {
    background-color: $color-dark-gray-new-2;
  }
}
