@import 'styles/settings.scss';

$icon-path: '../../../../src/assets/CheckMark.svg';

.c-checkbox {
  vertical-align: middle;

  input[type='checkbox'] + label:before {
    content: url($icon-path);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 18px;
    height: 18px;

    border: 1px solid $color-gray-3;
    border-radius: 50%;
    padding: 5px;
  }

  input[type='checkbox']:checked + label:before {
    background-color: $color-secondary;
    border-color: $color-secondary;
  }

  input[type='checkbox']:hover + label:hover {
    cursor: pointer;
  }

  input {
    appearance: none;
  }

  &__label-text {
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;
  }

  &__checkbox-holder {
    display: flex;
    align-items: center;

    &--large {
      input[type='checkbox'] + label:before {
        width: 25px;
        height: 25px;
      }
      .c-checkbox__label-text {
        font-weight: $fw-bold-light;
      }
    }

    &--medium {
      input[type='checkbox'] + label:before {
        width: 23px;
        height: 23px;

        font-size: $fs-base;
      }

      .c-checkbox__label {
        gap: $spacing-4xs;

        &__label-text {
          font-size: $fs-small;
        }
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;

    gap: $spacing-xs;
  }
}
