@import 'styles/settings.scss';

.c-time-away-type-form {
  width: 100%;
  padding: $spacing-s 0;
  border-bottom: 1px solid $color-dark-gray-new-2;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10%;

  &--modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: none;
    padding: 0;

    .c-time-away-type-form__form-inputs-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $spacing-s;
    }

    .c-time-away-type-form__heading {
      font-size: $fs-small;
      font-weight: $fw-semi-bold;
      color: $color-dark-gray;

      margin-bottom: $radius-xs;
    }

    .c-time-away-type-form__color-wrap {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .c-time-away-type-form__category-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .c-time-away-type-form__radio-wrap {
      margin: $spacing-4xs 0;
    }

    .c-time-away-type-form__colors-wrap {
      grid-template-columns: repeat(11, 1fr);
      margin-bottom: $spacing-3xl;
    }
  }

  .c-time-away-type-form__row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  &__heading {
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
    color: $color-light-gray-new-3;

    &--color {
      margin-top: $spacing-5xs;
    }
  }

  &__category-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__radio-inputs-wrap {
    margin-bottom: $spacing-4xl;
  }

  &__radio-wrap {
    margin: $spacing-s 0;
  }

  &__color-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: $spacing-2xs;
  }

  &__colors-wrap {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
  }
}
