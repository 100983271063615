@import 'styles/settings.scss';

.c-yes-no-button-group {
  width: 100%;

  display: flex;
  gap: $spacing-2xs;

  &--full {
    width: 100%;
  }
  &--medium {
    width: 65%;
  }

  &--half {
    width: 50%;
  }
}
