@import 'styles/settings.scss';

.c-workflows {
  display: flex;
  flex-direction: column;
  height: 100%;

  border-radius: $spacing-2xs;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $color-white;
    padding: $spacing-xl $spacing-l 0;
    border-radius: $radius-s $radius-s 0 0;
  }

  &__applied-filters {
    width: 100%;
    margin-bottom: $spacing-xs;
  }

  &__table {
    flex: 1;
    padding: $spacing-l 0 $spacing-l $spacing-l;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    overflow: auto;

    border-radius: 0 0 $radius-s $radius-s;
    background-color: $color-white;
  }

  &__table-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: inherit;

    overflow: auto;
    @include custom-scrollbar;
  }

  &__span-text {
    color: $color-dark-gray-new-2;
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
  }

  &__table-row {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    width: 100%;
    align-items: center;
    padding: $spacing-l 0;
    gap: $spacing-2xs;
    padding: $spacing-l $spacing-2xs;

    font-size: $fs-small;
    color: $color-gray-2;

    border-bottom: 1px solid $color-light-gray-new-1;
    border-radius: $radius-s;

    &:last-child {
      border: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-light-gray-new-1;
    }
  }

  &__open-button {
    vertical-align: middle;
    margin-right: $spacing-l;
    padding: $spacing-2xs $spacing-2xs;
    color: $color-blue;
    border-radius: $spacing-2xs;

    &:hover {
      cursor: pointer;
      background-color: $color-light-blue;
    }
  }

  &__no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: $spacing-l;

    background-color: $color-white;
    border-radius: $spacing-2xs;

    height: 100%;
  }

  &__no-content-title {
    font-size: $fs-medium;
    font-weight: 700;
    color: $color-dark-gray;
  }

  &__no-content-message {
    font-weight: $fs-small;
    font-weight: $fw-base;
    color: $color-gray-2;

    line-height: $spacing-xl;

    text-align: center;

    width: 30%;
  }
}
