@import 'styles/settings.scss';

.c-recovery {
  &__form-content {
    width: 50%;
    padding: 30px 35px;
    margin-top: $spacing-4xl;
    border-radius: 42px;

    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button-container {
    width: 100%;
    margin-top: 20px;
  }

  &__option-link {
    display: block;
    font-family: $font-family;
    font-weight: $fw-semi-bold;
    color: $color-gray-2;
    margin-top: $spacing-l;

    &:hover {
      cursor: pointer;
    }

    &:visited {
      color: $color-gray-2;
    }
  }

  &__title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;

    margin: 0 auto;
    gap: $spacing-4xs;

    @include breakpoint(phone) {
      width: 100%;
    }

    @include breakpoint(desktop) {
      width: 100%;
    }
  }
}
