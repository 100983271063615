@import 'styles/settings.scss';

.progress-bar {
  position: relative;
  height: inherit;
}

.tooltip {
  position: absolute;
  top: 150%;
  width: fit-content;

  color: $color-white;
  background-color: $color-dark-gray;
  padding: $spacing-5xs $spacing-l;
  border-radius: $spacing-l;
  z-index: 999;
}
