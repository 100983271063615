@import 'styles/settings.scss';

.c-workflows-table-head {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: $spacing-2xs;

  padding: $spacing-2xs;
  margin-right: $spacing-xl;

  border-bottom: 1px solid $color-dark-gray-new-2;

  font-size: $fs-base;
  color: $color-dark-gray;

  &__span {
    color: $color-dark-gray-new-2;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
  }
}
