@import 'styles/settings.scss';

.c-enter-hours-modal {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: $spacing-xl;

  &__row {
    width: 100%;

    display: flex;
    align-items: center;
    gap: $spacing-l;
  }

  &__time-picker-wrap {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-2xs;
  }

  &__label {
    color: $color-dark-gray;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;

    &--error {
      font-size: $fs-small;
      color: $color-error;

      margin: 0 auto;
    }
  }

  &__time-picker {
    min-height: 2.063rem;
    width: 100%;

    border: 1px solid $color-light-gray-new-2;
    border-radius: $radius-xs;
    padding: $spacing-4xs $spacing-s;

    color: $color-dark-gray-new-2;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
  }

  &__button {
    margin: 0 auto 0;
  }
}
