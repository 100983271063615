@import 'styles/settings.scss';

.c-section-info-edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__data-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    padding: $spacing-5xs 0;

    &--full-input {
      display: grid;
      grid-template-columns: 22% 78%;
    }
  }

  &__label-wrap {
    display: flex;
    align-items: center;
  }

  &__data {
    display: flex;
    align-items: center;

    font-weight: $fw-medium;
    color: $color-gray;
    font-size: $fs-small;
    font-family: $font-family;

    svg {
      height: 16px;
      width: 16px;
    }

    &--secondary {
      justify-content: flex-end;

      color: $color-dark-gray;
      font-weight: $fw-semi-bold;
    }

    &--missing {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: $spacing-s;
      min-height: 2.063rem;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &--extended {
      height: 2.063rem;
    }

    &--margin {
      margin-left: $spacing-s;
    }
  }

  &__label-icon {
    &--primary {
      path {
        fill: $color-primary;
      }
    }
  }

  &__required-holder {
    display: flex;
    flex: 1;

    position: relative;
  }

  &__required-star {
    color: $color-secondary;
    margin-left: $spacing-5xs;
    margin-bottom: $spacing-2xs;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }

  &__required-star-text {
    position: absolute;
    bottom: 100%;
    left: 5%;
    width: fit-content;
    background-color: $color-dark-gray;
    padding: $spacing-2xs;
    color: $color-white;
  }

  &__label {
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
    color: $color-light-gray-new-3;

    &::first-letter {
      text-transform: capitalize;
    }

    &--margin {
      margin-left: 12px;
    }

    &--required {
      &::after {
        content: '*';
      }
    }
  }

  &__icon {
    margin-top: $spacing-5xs;
  }

  &__missing-info-holder {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &--modal {
    .c-section-info-edit__data-container {
      display: flex;
      flex-direction: column;
      gap: $spacing-xs;
      padding: 0;
    }

    .c-section-info-edit__label {
      font-size: $fs-small;
      color: $color-dark-gray;
      font-weight: $fw-semi-bold;
    }
  }

  &:not(:last-child):not(.c-setion-info-edit--modal):not(.c-section-info-edit--settings-section) {
    border-bottom: 1px solid $color-gray-5;
  }
}
