@import 'styles/settings.scss';

.c-project {
  height: 100%;

  background-color: $color-light-gray-new-1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: $spacing-xs;
    margin: 0 $spacing-xl $spacing-l 0;

    background-color: $color-white;

    border-radius: $radius-m;

    &-buttons {
      display: flex;
      align-items: center;
    }
  }

  &__container {
    overflow: scroll;
    @include custom-scrollbar;

    height: 80vh;
  }

  &__input-container {
    display: flex;
    align-items: center;
    gap: $spacing-l;

    width: 70%;
  }

  &__phases {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-xl;
    overflow: visible;
    padding-bottom: $spacing-xl;
  }

  &__phases-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
  }

  &__card {
    min-height: 280px;
  }

  @include breakpoint(desktop-xxxl) {
    &__phases {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include breakpoint(tablet) {
    &__phases {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include breakpoint(phone) {
    &__phases {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
