@import 'styles/settings.scss';

.c-user-workflow-task-details {
  width: 27%;
  height: calc(100% - 2rem);
  padding: $spacing-xl;
  margin-right: $spacing-xl;

  position: absolute;
  right: 0;
  top: $spacing-l;
  z-index: 1;

  background-color: $color-white;
  border-radius: $radius-s;

  box-shadow: $box-shadow-3;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: $spacing-xl;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__icon-container-left {
    display: flex;
    align-items: center;

    gap: $spacing-4xs;
  }

  &__header-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    gap: $spacing-l;

    path {
      fill: $color-dark-gray;
    }
  }

  &__name {
    display: flex;
    font-size: $spacing-m;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new-2;
    padding: $spacing-5xs 0;

    margin-bottom: $spacing-s;

    border-top: 1px solid $color-dark-gray-new-2;
    border-bottom: 1px solid $color-dark-gray-new-2;
  }

  &__data-holder {
    display: flex;
    flex-direction: column;
    gap: $spacing-2xs;
    margin-top: $spacing-2xs;

    &--mt {
      margin-top: $spacing-s;
    }
  }

  &__data-holder-date {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &--border-top {
      border-top: 1px solid $color-gray-5;
      margin-top: $spacing-2xs;
      padding-top: $spacing-5xs;
    }

    &--border-bottom {
      border-bottom: 1px solid $color-gray-5;
      padding-bottom: $spacing-5xs;
    }
  }

  &__data-label-date {
    color: $color-gray-10;
    font-size: $fs-xsmall;
    font-weight: $fw-medium;

    display: flex;
    align-items: center;
  }

  &__data-label {
    color: $color-gray-10;
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
  }

  &__data-item {
    display: flex;
    gap: $spacing-2xs;
    align-items: center;
    color: $color-dark-gray;

    &--missing {
      color: $color-light-gray-new-2;
      font-size: $fs-xxsmall;
    }
  }

  &__person-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__icon {
    width: 22px;
    height: 22px;

    &--header {
      &:hover {
        cursor: pointer;
        path {
          fill: $color-secondary;
        }
      }
    }
  }

  &__icon-check {
    width: 16px;
    height: 16px;

    path {
      fill: $color-gray-3;
    }

    &:hover {
      cursor: pointer;
      path {
        fill: $color-success;
      }
    }
  }

  &__description {
    width: 100%;
    min-height: 250px;
    font-family: $font-family;
    font-size: $fs-xsmall;
    color: $color-dark-gray-new-2;

    border-radius: $spacing-5xs;
    border-color: $color-gray-3;
    background-color: $color-white;

    outline: none;
  }
}
