@import 'styles/settings.scss';
.c-delete-button-wrapper {
  position: relative;
}

.c-delete-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 23px;
  width: 24px;
  cursor: pointer;

  path {
    &:not(:last-child) {
      fill: $color-light-gray-new-3;
    }
    &:last-child {
      stroke: $color-light-gray-new-3;
    }
  }

  &:not(:disabled) {
    path {
      transition:
        fill $transition-1,
        stroke $transition-1;
    }
    &:hover {
      path {
        &:not(:last-child) {
          fill: $color-error;
          stroke: $color-error;
        }

        &:last-child {
          stroke: $color-error;
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    path {
      stroke: $color-light-gray-new-2;

      &:not(:last-child) {
        fill: $color-light-gray-new-2;
      }
    }
  }
}
