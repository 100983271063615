@import 'styles/settings.scss';

.c-input-radio {
  display: flex;
  align-items: center;

  &__label {
    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;
    letter-spacing: 0.24px;
    color: $color-dark-gray;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type='radio'] {
    appearance: none;
  }

  input[type='radio'] + label:before {
    display: inline-block;
    width: 20px;
    height: 20px;

    border: 2px solid $color-gray-3;
    border-radius: 50%;

    content: '\00a0';
    font-size: $fs-xsmall;
    line-height: $fs-base;
    font-family: sans-serif;

    margin-right: $spacing-2xs;
    padding: 0;
  }

  input[type='radio']:checked + label:before {
    display: flex;
    align-items: center;
    justify-content: center;

    content: '\2713';
    font-size: $fs-xsmall;
    background: $color-secondary;
    border-color: $color-secondary;
    color: $color-white;
  }

  input[type='radio']:hover + label:hover {
    cursor: pointer;
  }

  &--medium {
    .c-input-radio__label {
      font-size: $fs-small;
    }

    input[type='radio'] + label:before {
      display: inline-block;
      width: 25px;
      height: 25px;

      border: 2px solid $color-gray-3;
      border-radius: 50%;

      content: '\00a0';
      font-size: $fs-small;
      font-family: sans-serif;
    }

    input[type='radio']:checked + label:before {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      content: '\2713';
      background: $color-secondary;
      border-color: $color-secondary;
      color: $color-white;
      text-align: center;
    }
  }
}
