@import 'styles/settings.scss';

.c-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-2xl;

  font-size: $fs-xxsmall-plus;
  color: $color-gray-2;

  &__options-wrap {
    display: flex;
    align-items: center;
    gap: $spacing-l;
  }

  &__icon {
    height: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 12px;
    }

    path {
      fill: $color-gray-2;
    }

    &--rotate {
      transform: rotate(180deg);
    }

    &:hover {
      path {
        fill: $color-primary;
      }
    }

    &--disabled {
      pointer-events: none;

      path {
        fill: $color-light-gray-new-2;
      }
    }
  }
}
