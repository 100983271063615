@import 'styles/settings.scss';

.c-project-card {
  width: 100%;
  padding: $spacing-l;

  border-radius: $spacing-l;
  background-color: $color-white;
  cursor: pointer;

  transition: box-shadow $transition-1;

  &:hover {
    box-shadow: $box-shadow-5;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: $spacing-l;
  }

  &__title {
    font-size: $fs-base;
    font-weight: $fw-bold-light;
    color: $color-dark-gray;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-xl;

    &:not(:last-child) {
      margin-bottom: $spacing-l;
    }
  }

  &__row-subtitle {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-light-gray-new-3;
  }

  &__row-info {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new-2;
  }

  &__progress-wrap {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__completeness {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new-2;

    display: flex;
    justify-content: flex-end;
    min-width: 25%;
  }

  &__edit-option-link {
    display: flex;
    align-items: center;
    gap: $spacing-5xs;

    &:visited {
      color: $color-gray;
    }
  }
}
