@import 'styles/settings.scss';

.c-avatar {
  &__profile-photo-container {
    width: fit-content;
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__profile-photo {
    border-radius: 50%;
    object-fit: cover;
  }

  &__title {
    margin-top: $spacing-l;

    text-align: center;
    color: $color-gray-7;
    font-size: $fs-base-l;
    font-weight: $fw-bold-light;
    letter-spacing: 0;

    span {
      font-size: $fs-base-l;
      font-weight: 400;
    }
  }

  &__subtitle {
    text-align: center;
    color: $color-gray-2;
    font-size: $fs-base;
    margin-top: $spacing-5xs;
    font-weight: $fw-medium;
    letter-spacing: 0;
  }

  &__icon-button {
    position: absolute;
    top: 0;
    left: 45px;

    transition: transform $transition-1;

    &:hover {
      transform: scale(1.3);
    }
  }

  &--extra-small {
    .c-avatar__profile-photo {
      width: 25px;
      height: 25px;
    }
    .c-avatar__title,
    .c-avatar__subtitle {
      font-size: $fs-xsmall !important;
      font-weight: $fw-semi-bold;
    }
  }

  &--small {
    &:not(.c-avatar--horizontal) {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .c-avatar__profile-photo {
      height: 40px;
      width: 40px;
    }
  }

  &--small-plus {
    &:not(.c-avatar--horizontal) {
      margin: 0 auto;
    }
    .c-avatar__profile-photo {
      height: 48px;
      width: 48px;
    }
  }

  &--medium-minus {
    display: flex;
    align-items: center;
    flex-direction: column;

    .c-avatar__profile-photo {
      width: 60px;
      height: 60px;
    }

    .c-avatar__title {
      font-weight: $fw-bold-light;
      font-size: $fs-small;
    }
  }

  &--medium {
    display: flex;
    align-items: center;
    flex-direction: column;

    .c-avatar__profile-photo {
      height: 140px;
      width: 140px;

      &:not(.c-avatar--horizontal) {
        flex-direction: row;
        border: 4px solid $color-white;
      }
    }

    .c-avatar__title {
      font-size: $fs-base-xl !important;

      &:not(.c-avatar--horizontal) {
        margin-top: $spacing-2xs;
        font-size: $fs-medium;
      }
    }

    .c-avatar__subtitle {
      &:not(.c-avatar--horizontal) {
        margin-top: $spacing-5xs;
        font-size: $fs-xsmall;
        color: $color-dark-gray-new-2;
      }
    }
  }

  &--large {
    .c-avatar__profile-photo {
      height: 145px;
      width: 145px;
    }
  }

  &--horizontal {
    display: flex;
    align-items: center;
    gap: 0 6px;

    .c-avatar__title,
    .c-avatar__subtitle {
      font-size: 1rem;
      text-align: left;
    }
    .c-avatar__title {
      margin: 0;
    }

    .c-avatar__subtitle {
      color: $color-black;
      font-weight: 200;
    }
  }

  &--timeline {
    &:not(.c-avatar--horizontal) {
      margin: 0 auto;
    }
    .c-avatar__profile-photo {
      height: 38px;
      width: 38px;
    }

    .c-avatar__title {
      font-weight: $fw-bold;
      font-size: $fs-small;
      text-align: left;
    }

    .c-avatar__subtitle {
      font-weight: $fw-medium;
      font-size: $fs-xsmall;
      text-align: left;
    }
  }

  &--with-icon {
    max-width: 120px;
  }

  &--clickable {
    cursor: pointer;
    .c-avatar__profile-photo {
      cursor: pointer;
      transition: transform $transition-1;
    }
    &:hover {
      .c-avatar__profile-photo {
        transform: scale(1.3);
      }
    }
  }
}
