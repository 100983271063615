@import 'styles/settings.scss';

.c-export-data-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  padding: 0 $spacing-l;
  height: fit-content;

  font-family: $font-family;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &--first {
    padding-left: 0;
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: $spacing-5xs;

    padding-bottom: $spacing-s;

    font-size: $fs-base;
    font-weight: $fw-bold;
    font-style: normal;

    &--error-message {
      padding-bottom: 0;
    }
  }

  &__error-message {
    font-size: $fs-xsmall;
    color: $color-red;
  }

  &__checkbox-holder {
    display: flex;
    gap: 150px;
    align-items: center;

    padding: $spacing-2xs 0;
    padding-left: $spacing-5xs;

    color: $color-dark-gray;
    font-size: $fs-small;
  }
}
