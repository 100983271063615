@import 'styles/settings.scss';

.c-settings-container {
  width: 100%;
  height: 100%;

  padding: $spacing-l 0 $spacing-l $spacing-xl;

  display: flex;
  flex-direction: row;

  &__sidebar-container {
    width: 25%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: $radius-s;
    background-color: $color-white;

    @media (min-width: 1800px) {
      width: 20%;
    }
  }

  &__content {
    width: 100%;

    padding-left: $spacing-xl;

    overflow: auto;
    @include custom-scrollbar;
  }
}
