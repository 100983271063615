@import 'styles/settings.scss';

.c-sections-column {
  display: flex;
  flex-direction: column;
  gap: $spacing-l;

  &__add-button-wrap {
    min-height: 392px;

    @include breakpoint(desktop-xxl) {
      min-height: 402.75px;
    }

    @include breakpoint(desktop-xxxl) {
      min-height: 413.75px;
    }
  }
}
