@import 'styles/settings.scss';

.c-time-away-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: $spacing-3xl;
  border-radius: $radius-xs;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 37.5px;
    margin-bottom: $spacing-3xl;
  }

  &__list-header {
    padding-bottom: $spacing-xs;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    align-items: center;

    div {
      display: grid;
      grid-template-columns: 55% 45%;
    }

    span {
      color: $color-dark-gray-new-2;
      font-size: $fs-xsmall;
      font-weight: $fw-bold-light;
    }
  }

  &__top {
    background-color: $color-white;
    padding: $spacing-l $spacing-xl;
  }

  &__item {
    padding: $spacing-l 0;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    align-items: center;

    border-bottom: 1px solid $color-light-gray-new-1;

    span {
      font-size: $fs-xsmall;
      color: $color-dark-gray-new-2;
      font-weight: $fw-medium;
    }

    path {
      fill: $color-secondary;
    }

    &--missing-data {
      font-size: $fs-xsmall;
      color: $color-gray;
      font-weight: $fw-base;
    }
  }

  &__date-range {
    display: grid;
    grid-template-columns: 40% 15% 45%;
    align-items: center;
  }

  &__missing-data-message {
    padding: $spacing-l 0;
    color: $color-gray-3;
  }

  &__pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
