@import 'styles/settings.scss';

.c-people-overview {
  height: calc(100vh - $toolbar-height);
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__table {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: $color-white;

    overflow-y: auto;
  }

  &__table-header {
    padding: $spacing-xl 0;
    margin: 0 $spacing-2xl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray-5;

    &--right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $spacing-6xl;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    padding-bottom: $spacing-s;

    overflow-y: auto;
    @include custom-scrollbar;

    &--missing {
      padding: $spacing-xl $spacing-l;
      font-size: $fs-small;
      color: $color-gray-2;
    }
  }
}
