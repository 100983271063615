@import 'styles/settings.scss';

.c-add-compensation-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-l;
  margin-top: $spacing-2xs;

  &__dropdown-holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: $spacing-2xs;

    label {
      font-size: $fs-small;
      font-weight: $fw-semi-bold;
      color: $color-dark-gray;
    }

    select {
      padding: $spacing-5xs;
      border-radius: $spacing-5xs;
      border: 1px solid $color-gray-3;
      color: $color-dark-gray;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__button-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-top: $spacing-4xl;
  }
}
