@import 'styles/settings.scss';

.c-organization-chart {
  width: 100%;
  height: 100%;
  padding: $spacing-l $spacing-xl;
  border-radius: $spacing-2xs;

  &__content {
    height: 100%;
    width: 100%;

    background-color: $color-light-gray-new-8;
    background-image: radial-gradient(#000 1px, transparent 0);
    background-size: 30px 30px;
    border-radius: $spacing-2xs;
  }
}

.c-organization-chart-wrapper {
  height: 100%;
  position: relative;
  overflow: hidden;
}
