@import 'styles/settings.scss';

.c-checkmark {
  width: $spacing-2xl;
  height: $spacing-2xl;

  border-radius: 50%;
  padding: 5px;
  border: 1px solid $color-light-gray-new-2;
  overflow: visible;

  transition:
    background-color,
    border $transition-1;

  path {
    stroke: $color-light-gray-new-2;
    fill: $color-light-gray-new-2;
  }

  &--checked,
  &--clickable:hover {
    background-color: $color-success;
    border: 1px solid $color-success;

    path {
      stroke: $color-white;
      fill: $color-white;
    }
  }

  &--clickable {
    cursor: pointer;
  }
}
