@import 'styles/settings.scss';

.c-profile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 25%;

  padding: $spacing-2xl;
  margin-right: $spacing-xl;

  font-size: $fs-xsmall;
  font-weight: $fw-medium;
  color: $color-dark-gray-new-2;

  border-radius: $radius-s;

  background-color: $color-white;
  position: relative;

  &__user-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  }

  &__user-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-5xs;

    position: relative;
    margin-bottom: $spacing-l;
  }

  &__user-avatar-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65%;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
  }

  &__user-avatar-input-label {
    position: absolute;
    top: 49%;
    right: calc(50% - 46px);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6) url('../../../assets/UploadPhotoIcon.svg') no-repeat center;
    cursor: pointer;

    transition: background $transition-1;

    &:hover {
      background: rgba(0, 0, 0, 0.6) url('../../../assets/UploadPhotoHoverIcon.svg') no-repeat
        center;
    }
  }

  &__data {
    font-size: $fs-xsmall;
    color: $color-light-gray-new-3;
    font-weight: $fw-medium;
  }

  &__section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $spacing-l 0;
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 130px;
    width: 100%;
    padding: $spacing-xs;
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 2px;

    margin: $spacing-xs 0;

    width: 100%;
  }

  &__link-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: $spacing-xs $spacing-s;

    &:hover,
    &--active {
      cursor: pointer;
      background-color: $color-gray-8;
      border-radius: $radius-s;

      .c-profile-nav__icon {
        path {
          fill: $color-primary;
        }
      }
    }
  }

  &__link {
    width: 100%;
    display: flex;
    gap: $spacing-2xs;
    align-items: center;

    &--secondary {
      width: 100%;
      padding: $spacing-s 0;
      font-weight: $fw-bold-light;
      color: $color-dark-gray-new-2;
      text-align: center;

      border: 1px solid $color-light-gray-new-2;
      border-radius: $radius-s;
      transition: border $transition-1;

      &:hover {
        border-color: $color-primary;
      }
    }

    &--secondary-active {
      border-color: $color-light-gray-new-4;
      background-color: $color-light-gray-new-4;
    }

    &--resend:hover {
      cursor: pointer;
    }
  }

  &__link-text {
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
    color: $color-gray-7;
  }

  &__icon {
    height: 18px;
    width: 18px;
  }

  &__error-icon {
    path {
      fill: $color-secondary;
    }
  }

  &__offboarding {
    display: flex;
    flex-direction: column;

    padding: $spacing-l $spacing-5xs;

    width: 100%;

    border-bottom: 1px solid $color-gray;

    span {
      margin-left: 0;
    }
  }

  &__profile-compliteness {
    width: 100%;
    padding: $spacing-l $spacing-5xs;

    display: flex;
    flex-direction: column;
  }

  &__profile-compliteness-text {
    padding-bottom: $spacing-s;
    display: flex;
    justify-content: space-between;

    &--percentage {
      font-weight: $fw-bold-light;
    }
  }

  @media (min-width: 1800px) {
    width: 20%;
  }
}
