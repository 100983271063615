@import 'styles/settings.scss';

.c-emergency-contacts {
  display: flex;
  flex-direction: column;
  gap: $spacing-l;

  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-l;
  }

  &__card {
    min-height: 220px;
    width: 100%;
  }

  &__icon {
    width: 18px;
    height: 18px;

    path {
      fill: $color-secondary;
    }
  }

  &__modal {
    padding-top: $spacing-l;
    gap: $spacing-l;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__modal-inputs {
    width: 100%;
    gap: $spacing-l;
    display: flex;
    flex-direction: column;

    margin-bottom: $spacing-4xl;
  }
}
