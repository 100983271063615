@import 'styles/settings.scss';

.c-approval-item {
  &__options-container {
    display: flex;
    flex-direction: column;

    padding-bottom: $spacing-l;
    border-bottom: 1px solid $color-dark-gray-new-2;
  }

  &__option {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-l;
    color: $color-dark-gray-new-2;
  }

  &__input-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__label {
    font-weight: $fw-bold-light;
    font-size: $fs-xsmall;
  }

  &__description {
    margin-left: $spacing-3xl;
    font-weight: $fw-medium;
    font-size: $fs-xsmall;
    color: $color-gray;
  }

  &__radio {
    cursor: pointer;
    background-color: $color-secondary !important;

    &:checked {
      background-color: $color-secondary !important;
    }
  }
}
