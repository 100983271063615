@import 'styles/settings.scss';

.c-error-page {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    max-width: 75%;
  }
}
