@import 'styles/settings.scss';

.c-save-template-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-l;

  &__save-button {
    margin-top: $spacing-2xs;
  }
}
