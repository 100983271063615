@import 'styles/settings.scss';

$icon-path: '../../../../../src/assets/Circle.svg';

.c-project-progress {
  width: 100%;
  display: flex;
  align-items: center;

  position: relative;

  &:not(:last-child) {
    &::after {
      content: url($icon-path);

      height: 10px;
      width: 10px;

      position: absolute;
      right: -5px;
      top: -5px;
      z-index: 2;
    }
  }
}
