@import 'styles/settings.scss';

.c-section {
  display: flex;
  flex-direction: column;

  padding: $spacing-xs $spacing-xl;
  margin-bottom: $spacing-l;

  border-radius: $spacing-l;
  background: $color-white;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    min-height: $spacing-4xl;
    margin-bottom: $spacing-s;
  }

  &__title {
    color: $color-dark-gray-new-2;
    font-size: $fs-base;
    font-weight: $fw-bold-light;
  }

  &__options-container {
    display: flex;
    align-items: center;
  }

  &__option {
    margin-left: $spacing-l;
    font-size: $fs-base;
    color: $color-gray-2;
    font-family: $font-family;
    font-weight: $fw-bold;

    background: transparent;
    border: none;

    &--additional {
      margin-right: $spacing-l;
    }

    &:hover {
      cursor: pointer;
      color: $color-blue;
    }
  }

  &__content {
    overflow: none;
  }
}
