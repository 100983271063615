@import 'styles/settings.scss';

.c-crop-image {
  &__preview {
    min-width: 300px;
    min-height: 300px;
  }

  &__canvas {
    object-fit: contain;
    width: 150px;
    height: 150px;
    display: none;
  }
}
