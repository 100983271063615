@import 'styles/settings.scss';

.c-remaining-days {
  width: 100%;
  margin-bottom: $spacing-l;

  &__number-of-days {
    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-dark-gray;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  &__label {
    flex: 1;
    white-space: nowrap;

    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;
    letter-spacing: $letter-spacing-m;
  }
}
