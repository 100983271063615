@import 'styles/settings.scss';

.c-create-project-modal {
  margin-top: $spacing-l;
  width: 100%;

  &__button-container {
    display: flex;
    justify-content: center;
  }

  &__error-message {
    font-size: $fs-xsmall;
    color: $color-error;

    width: 100%;
    min-height: 30px;
    margin-top: $spacing-4xs;
  }
}
