@import 'styles/settings.scss';

.c-year-calendar {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacing-xl;
    min-height: 37.5px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-2xs;
    justify-content: center;
  }

  &__wrapper {
    flex: 1;
    padding-top: $spacing-l;
    border-radius: $radius-s;
    gap: $spacing-s;
  }

  &__months {
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__month {
    width: 100% !important;
    padding: 0 $spacing-2xl !important;

    display: flex;
    flex-direction: column;
  }

  &__month-name {
    cursor: pointer;

    &:hover {
      color: $color-secondary;
    }

    &--hidden {
      display: none;
    }

    &--single-month {
      padding: 0 !important;
      margin-bottom: $spacing-l;

      display: flex;
      justify-content: flex-start;
      text-align: center;

      font-size: $fs-base;
      color: $color-gray-7 !important;
      font-weight: $fw-bold-light !important;
    }
  }

  &__week-day {
    color: $color-dark-gray-new-2;
    font-weight: $fw-bold-light;
    padding-bottom: $spacing-2xs;
    border-bottom: 1px solid $color-gray-7;
  }

  &__days {
    height: 100%;
  }

  &__day {
    height: 100%;
    width: 100%;

    padding: $spacing-2xs;

    display: flex;
    align-items: center;
    justify-content: center;

    &--start-date {
      border-top-left-radius: $spacing-l;
      border-bottom-left-radius: $spacing-l;
    }

    &--end-date {
      border-top-right-radius: $spacing-l;
      border-bottom-right-radius: $spacing-l;
    }

    &--passive {
      span {
        background-color: transparent;
      }
    }
  }

  &__month-calendar {
    padding: 0 $spacing-xl $spacing-xl 0;
    display: flex;
    flex-direction: column;
    gap: $spacing-xl;
  }

  &__option-button {
    display: flex;
    align-items: center;

    font-size: $fs-small;
    color: $color-dark-gray;

    cursor: pointer;
    &:hover {
      color: $color-secondary;
      path {
        fill: $color-secondary;
      }
    }
  }

  &__arrow-icon {
    path {
      fill: $color-secondary !important;
    }
  }
}
