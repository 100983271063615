@import 'styles/settings.scss';

.c-banking-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-2xl;

  width: 100%;
  max-height: 95vh;

  font-family: $font-family;

  &__buttons {
    display: flex;
    align-items: center;
    gap: $spacing-xl;
    padding: $spacing-xl 0;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    input {
      border: 1px solid #edf1f2;
    }
  }

  &__save {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: $spacing-7xl;
  }
}
