@import 'styles/settings.scss';

.c-documents-item {
  display: flex;
  flex-direction: column;

  padding: $spacing-xs $spacing-xl;

  border-radius: $radius-s;

  font-size: $fs-xsmall;

  background: $color-white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-4xl;
    min-height: $spacing-4xl;
    margin-bottom: $spacing-s;
    padding-right: $spacing-2xs;

    &--warning {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: $color-red;
    }
  }

  &__title {
    color: $color-gray-7;
    font-size: $fs-base;
    font-weight: $fw-bold-light;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    &--columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10%;
    }
  }
}
