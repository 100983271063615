@import 'styles/settings.scss';

.c-book-time-away-modal {
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__calendar-options {
    width: 100%;

    display: flex;
    gap: $spacing-2xs;
    justify-content: space-between;
  }

  &__calendar-option {
    width: 50%;
  }

  &__inputs-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-l;

    margin-bottom: $spacing-3xl;
  }

  &__input-label {
    display: inline-block;
    margin-bottom: $spacing-2xs;

    font-size: $fs-small;
    color: $color-dark-gray;
    letter-spacing: 0.01rem;
    font-weight: $fw-semi-bold;
  }
}
