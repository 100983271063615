@import 'styles/settings.scss';

.c-export-data-modal {
  display: flex;
  flex-direction: column;

  overflow: visible;

  max-height: 70vh;
  width: 100%;

  &__buttons {
    display: flex;
    justify-content: space-between;

    padding-top: $spacing-l;

    border-top: 1px solid $color-gray-5;
  }

  &__export-inputs {
    display: flex;
    justify-content: space-between;
    gap: $spacing-2xs;

    padding-top: $spacing-2xs;
  }

  &__sections-column {
    display: flex;
    flex-direction: column;
    gap: $spacing-l;
  }

  &__checkbox-sections {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    padding: $spacing-l 0;
    border-top: 1px solid $color-dark-gray;

    overflow: auto;
    @include custom-scrollbar;
  }

  &__button-wrap {
    position: relative;
  }

  &__save-icon-wrap {
    background-color: $color-primary;
    border-radius: 50%;
    padding: $spacing-2xs;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color $transition-1;

    &--disabled {
      background-color: $color-light-gray-new-2;
    }
  }

  &__save-icon {
    width: 15px;
    height: 15px;

    path {
      fill: $color-white !important;
    }
  }

  &__small-divider {
    display: flex;
    justify-content: center;
    margin-bottom: $spacing-l;

    span {
      width: 80%;
      border-bottom: 1px solid $color-gray;
    }
  }
}
