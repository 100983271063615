@import 'styles/settings.scss';

.c-chart-node {
  padding: $spacing-2xs;
  width: 200px;
  height: 260px;

  border-radius: $radius-m;
  outline: 1.5px solid transparent;

  background-color: $color-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.19);
  transition: outline $transition-1;

  &__banner {
    width: calc(100% + $spacing-2xs * 2);
    margin: -$spacing-2xs 0 - $spacing-2xs;
    height: 50px;
    transition: background-color $transition-1;
    border-radius: $radius-m $radius-m 0 0;
  }

  &__action {
    float: right;
    margin-right: 15px;
    margin-top: 15px;
  }

  &__user-info {
    width: 100%;
    text-align: center;
  }

  &__avatar {
    margin-top: -30px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__avatar-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 0 auto $spacing-l;
  }

  &__avatar-title {
    font-weight: $fw-bold-light;
    font-size: $fs-small;
    text-align: center;
  }

  &__department {
    color: $color-dark-gray;
    font-size: $fs-xsmall;
    margin: 0 auto $spacing-xs;
  }

  &__details {
    font-size: $fs-xsmall;
    font-weight: $fw-base;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-4xs;
    margin-top: $spacing-xl;
  }

  &__details-icon {
    path {
      fill: $color-primary-light;
      stroke-width: 0;
    }
  }

  &__job-title {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-dark-gray;

    border-radius: $radius-s;
    background-color: #ebebeb;
    padding: $spacing-4xs $spacing-l;
    margin: $spacing-l auto;

    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__new-tab-icon {
    width: 16px;
    height: 16px;

    path {
      stroke-width: 0;
    }
  }

  &--main-node {
    height: 220px;
  }

  &--with-transitions {
    .c-chart-node__new-tab-icon {
      transition: opacity $transition-1;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &--active {
    outline-color: $color-primary-light;
  }

  &--disabled {
    .c-chart-node__banner {
      background-color: $color-gray;
    }
    &.c-chart-node--with-transitions {
      .c-chart-node__avatar-img {
        filter: grayscale(100%);
      }
    }
  }
}
