@import 'styles/settings.scss';

.c-text-divider {
  font-weight: $fw-semi-bold;
  font-size: $fs-small;
  color: $color-dark-gray;

  position: relative;
  width: 100%;
  text-align: center;
  margin: $radius-l 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 46%;
    height: 1px;
    background-color: $color-gray-9;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
