@import 'styles/settings.scss';

.c-filter-button {
  font-size: $fs-xxsmall-plus;
  font-weight: $fw-semi-bold;
  color: $color-dark-gray-new-2;
  background-color: transparent;
  padding: $spacing-xs $spacing-l;
  border-radius: $radius-xs;
  transition: background-color $transition-1;

  display: flex;
  align-items: center;
  gap: $spacing-4xs;

  &:hover,
  &--selected {
    cursor: pointer;

    &.c-filter-button__highlight-mode {
      &--dark {
        background-color: $color-light-gray-new-5;
      }

      &--light {
        background-color: $color-white;
      }
    }
  }

  &__close-icon {
    position: absolute;

    top: -5px;
    right: -5px;

    height: 14px;
    width: 14px;
  }

  &__selective-button-wrap {
    position: relative;

    .c-filter-button {
      &:hover,
      &--selected {
        cursor: default;
      }
    }
  }
}
