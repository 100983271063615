@import 'styles/settings.scss';

.c-document-drop-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 180px;

  transition: box-shadow $transition-1;

  &__file-input {
    display: none;
  }

  &__option {
    font-size: $fs-xsmall;
    color: $color-gray-2;
    font-weight: $fw-semi-bold;
    letter-spacing: 0.12px;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-l;
    width: 100%;

    input[type='file'] {
      display: none;
    }
  }

  &__input-file-holder {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
    margin: $spacing-xl 0 $spacing-4xl;
  }

  &__input-file-label {
    border: 1px solid $color-light-gray-new-2;
    width: 100%;
    padding: $spacing-3xs $spacing-s;
    border-radius: $radius-xs;
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    letter-spacing: $letter-spacing-m;
    line-height: normal;

    color: $color-dark-gray;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-5;
      border-color: $color-gray-5;
    }

    &--invalid {
      outline: 1px solid $color-red;
    }
  }

  &__upload-input {
    width: 100%;
    display: flex;
    flex: 1;
  }
}
