@import 'styles/settings.scss';

.c-your-locations {
  &__modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-2xl;
  }

  &__modal-inputs-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
  }

  &__empty-state {
    font-size: $fs-small;
    padding-left: $spacing-2xs;

    min-height: 30px;
  }
}
