@import 'styles/settings.scss';

.c-pop-up-modal {
  display: flex;
  flex-direction: column;
  font-family: $font-family;

  background-color: $color-white;
  border-radius: 30px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: $spacing-3xl $spacing-2xl $spacing-xl;
  }

  &__title {
    margin-right: $spacing-7xl;
    font-weight: $fw-bold-light;
    font-size: $fs-base-xl;
    color: $color-dark-gray;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 $spacing-2xl $spacing-3xl;
  }

  &--confirmation-modal {
    .c-pop-up-modal__header {
      margin: $spacing-l $spacing-l $spacing-2xs;
    }

    .c-pop-up-modal__title {
      margin: 0 auto;

      font-size: $fs-base-xl;
      font-weight: $fw-bold-light;
      letter-spacing: 0.36px;
    }

    .c-pop-up-modal__content {
      margin: 0 $spacing-l $spacing-l;
    }
  }
}
