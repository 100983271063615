@import 'styles/settings.scss';

.c-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  padding: $spacing-2xs $spacing-5xs $spacing-2xs $spacing-2xl;
  margin-bottom: $spacing-5xs;
  width: 100%;
  border-radius: $radius-xs;

  transition:
    color $transition-1,
    background-color $transition-1;

  &--selected {
    background-color: $color-primary;

    .c-sidebar-item__label {
      color: $color-white;
    }

    .c-sidebar-item__icon {
      path {
        fill: $color-white;
      }
    }
  }

  &--collapsed {
    width: auto;
    padding: 12px;
    border-radius: 100px;

    animation: 1s ease-in-out 0s 1 fadeIn;
  }

  &:hover {
    background-color: $color-primary-light;

    .c-sidebar-item__label {
      color: $color-white;
    }

    .c-sidebar-item__icon {
      path {
        fill: $color-white !important;
        transition: fill $transition-1;
      }
    }
  }

  &__wrap {
    position: relative;

    &--submenu {
      background-color: $color-dark-gray-new-1;
      border-radius: $radius-xs;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 14px;
      height: 18px;

      path {
        fill: $color-gray-3;
      }
    }

    &--collapsed {
      display: flex;
      margin: 0;
    }
  }

  &__icon {
    display: flex;

    path {
      fill: $color-gray-3;
    }
  }

  &__label {
    margin-left: $spacing-xs;
    display: flex;
    align-items: center;

    font-weight: $fw-bold-light;
    font-size: $fs-xsmall;
    padding: 0 $spacing-2xs;
    color: $color-gray-3;

    animation: 1s ease-in-out 0s 1 fadeIn;
  }

  &__expand-button {
    width: 16px;
    height: 16px;

    transition: transform $transition-1;

    path {
      fill: $color-gray-3;
    }

    &--expanded {
      transform: rotate(90deg);
    }

    &--hover,
    &:hover {
      path {
        fill: $color-white;
      }
    }
  }

  &__submenu {
    &:not(.c-sidebar-item__submenu--collapsed) {
      margin-left: $spacing-xl;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
