@import 'styles/settings.scss';

.c-phase-user-list {
  &__list-item {
    padding: $spacing-2xs 0;

    display: flex;
    flex: 0 0 33%;
    gap: $spacing-2xs;
    height: 100%;
  }

  &__empty {
    font-size: $fs-small;
    color: $color-gray-3;

    display: block;
    padding: $spacing-2xs 0;
  }

  &__more-icon {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;

    border: 1px solid $color-primary;
    border-radius: 50%;
    width: 28px;
    height: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
