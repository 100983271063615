@import 'styles/settings.scss';

.c-direct-reports {
  display: flex;
  flex-direction: column;

  padding: $spacing-xs $spacing-xl $spacing-5xl;

  border-radius: $radius-s;
  background: $color-white;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.063rem;

    margin-bottom: $spacing-xl;
  }

  &__title {
    color: $color-gray-7;
    font-size: $fs-base;
    font-weight: $fw-bold-light;
  }

  &__dropdown-holder {
    width: 40%;
    margin-top: $spacing-xl;
  }

  &__no-data {
    font-size: $fs-xsmall;
    color: $color-gray;
    font-weight: $fw-semi-bold;
  }
}
