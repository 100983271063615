@import 'styles/settings.scss';

.c-away-users {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  &__empty-page {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }
}
