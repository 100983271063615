@import 'styles/settings.scss';

.c-applied-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  color: $color-dark-gray;
  background-color: $color-white;
  padding: $spacing-l $spacing-2xl;
  border: 1px solid $color-light-gray-new-6;

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: $spacing-xs;
  }

  &__clear-filters {
    display: flex;
    align-items: center;
    margin-left: auto;

    &--icon {
      height: 12px;
      width: 12px;

      circle {
        fill: #7c7c7c;
      }

      path {
        fill: $color-white !important;
      }
    }
  }

  &--opened-filters {
    border-top: 0;
  }
}
