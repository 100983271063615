@import 'styles/settings.scss';

.c-user-workflow-task {
  height: 50px;
  display: grid;
  grid-template-columns: 50% 16.6% 16.6% 16.6%;
  align-items: center;
  padding: 0 $spacing-2xs;
  border-bottom: 1px solid $color-gray-5;

  &:last-child {
    border: 0;
  }

  &__main {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    color: $color-gray-7;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
  }

  &__date-title {
    color: $color-gray-7;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
  }

  &__details {
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
  }

  &__details-text {
    color: $color-gray-2;
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
  }

  &__icon-grab {
    cursor: grab;
    visibility: hidden;

    &--hovered {
      visibility: visible;
    }

    rect {
      stroke: $color-gray-2;
    }
  }

  &__check {
    height: 22px;
    width: 17px;

    &--unchecked {
      path {
        fill: $color-gray;
      }
    }
  }

  &__date {
    color: $color-gray-2;
    font-size: $fs-xsmall;
    font-weight: $fw-medium;
  }

  &__assignee {
    display: flex;
    gap: $spacing-2xs;
    align-items: center;
    color: $color-dark-gray;

    path {
      fill: $color-dark-gray;
    }
  }
}
