@import 'styles/settings.scss';

.c-manage-access {
  display: flex;
  justify-content: space-between;
  gap: 10%;

  &__column {
    flex: 1;
  }

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-label {
    font-weight: $fw-medium;
    font-size: $fs-xsmall;
    color: $color-light-gray-new-3;

    display: flex;
    align-items: center;
    gap: $spacing-s;
  }

  &__item-name {
    font-family: $font-family;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
    line-height: 16.8px;
    color: $color-dark-gray;
  }

  &__item-logo {
    margin: 0 20px 0 auto;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__upload-button {
    margin-left: auto;
    font-weight: $fw-medium;
    font-size: $fs-xsmall;
    color: $color-gray;

    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
    }

    &--upload-image {
      margin: 0;
      border: 1px solid $color-gray;
      border-radius: $radius-xs;
      padding: 7px $spacing-xs;
    }
  }

  &__modal-wrap {
    width: 100%;
  }

  &__modal-logo-wrap {
    position: relative;
    margin-bottom: $spacing-xl;
    display: flex;
    justify-content: center;
    border-radius: 50%;

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  &__modal-logo {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__confirmation-buttons-wrap {
    display: flex;
    justify-content: space-between;
    padding: 0 $spacing-4xl;
    gap: $spacing-xl;
  }

  &__chose-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-5xs;

    position: relative;
    margin-bottom: $spacing-l;
  }

  &__chose-file-label {
    position: absolute;
    top: 70%;
    right: 30%;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.6) url('../../../../../assets/UploadPhotoIcon.svg') no-repeat center;
    cursor: pointer;

    transition: background $transition-1;

    &:hover {
      background: rgba(0, 0, 0, 0.6) url('../../../../../assets/UploadPhotoHoverIcon.svg') no-repeat
        center;
    }
  }

  &__input-file-container {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  &__input-file {
    margin-top: $spacing-xl;

    &::file-selector-button {
      border: 0;
      color: $color-white;
      background-color: $primary-color;
      padding: $spacing-l $spacing-xl;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: darken($primary-color, 5%);
      }
    }
  }

  &__input-field {
    padding: $spacing-l $spacing-2xs;
    border: 1px solid $color-gray;
    border-radius: $spacing-2xs;
    color: $color-gray-2;
  }
}
