@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-ExtraLight.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-ExtraLight.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-ExtraBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../../assets/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
