@import 'styles/settings.scss';

.c-date-picker {
  display: flex;
  flex-direction: column;
  font-weight: $fw-semi-bold;
  position: relative;

  width: 100%;

  cursor: pointer;

  &__field-holder {
    display: flex;
    align-items: center;
    width: inherit;
  }

  &__field {
    width: 100%;
    min-height: 2.063rem;

    padding: $spacing-xs $spacing-l;

    border: 1px solid $color-light-gray-new-3;
    border-radius: $radius-xs;

    &--align-right {
      text-align: right;
    }

    &--error-border {
      border-color: $color-error;
    }
  }

  ::placeholder {
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
    color: $color-light-gray-new-3;
  }

  &__calendar {
    position: absolute;
    top: 100%;
    z-index: 1;
    box-shadow: 6px 8px 24px rgba(0, 0, 0, 0.2);
    background-color: $color-white;

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
    }

    &--center {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__invalid-icon {
    position: absolute;
    right: $spacing-2xs;
    top: 0;
    transform: translateY(50%);
  }
}
