@import 'styles/settings.scss';

.c-task {
  display: grid;
  grid-template-columns: 30% repeat(2, 15%) repeat(4, 10%);
  align-items: center;

  padding: $spacing-s 0;

  border-bottom: 1px solid $color-light-gray-new-1;

  &__container {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
  }

  &__status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 3px;
    border: 1px solid $color-light-gray-new-2;
    overflow: hidden;

    path {
      fill: $color-light-gray-new-2;
    }

    &--checked {
      background-color: $color-success;
      border-color: $color-success;

      path {
        fill: $color-white;
      }
    }
  }

  &__column {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-gray-2;

    &--highlighted {
      font-size: $fs-small;
      font-weight: $fw-bold-light;
      color: $color-dark-gray;
    }

    &--centered {
      justify-self: center;
    }
  }
}
