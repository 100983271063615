@import 'styles/settings.scss';

.c-timeline-page {
  height: 100%;

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;

    padding: $spacing-2xs $spacing-xl $spacing-l;

    background-color: $color-white;
    border-radius: $radius-s;
  }

  &__color-legend {
    margin-bottom: $radius-xs;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__month-picker-wrap {
    padding: $spacing-2xs 0;
  }

  &__spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    padding: 3rem;
  }
}
