@import 'styles/settings.scss';

.c-layout {
  height: 100vh;
  display: flex;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__content {
    height: calc(100vh - $toolbar-height);
    background-color: $color-light-gray-new-8;
  }
}
