@import 'styles/settings.scss';

.c-date-range-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  &__inputs {
    display: flex;
    gap: $spacing-l;
    width: inherit;
  }

  &__input-container {
    flex: 1;
    display: flex;
    gap: $spacing-2xs;
  }

  &__input-label {
    font-size: $fs-small;
    color: $color-dark-gray;
    font-weight: $fw-semi-bold;
  }

  &__input {
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: $spacing-2xs;

    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;
    background-color: $color-white;

    border-radius: 10px;
    border: 0.5px solid $color-gray;

    &:hover {
      cursor: pointer;
    }

    &--invalid {
      outline: 1px solid $color-red;
    }
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacing-2xs;
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    z-index: 999;
    width: 100%;
    max-width: 100%;

    border-radius: $spacing-l;
    background-color: $color-white;
    box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.2);

    &--center {
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 80px);
    }

    &--left {
      left: -50%;
    }

    &--right {
      right: -50%;
    }
  }

  &--horizontal {
    .c-date-range-picker__input-container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .c-date-range-picker__input {
      width: 50%;
    }
    .c-date-range-picker__input-label {
      color: $color-light-gray-new-3;
      font-weight: $fw-bold-light;
    }
  }

  &--vertical {
    .c-date-range-picker__input-container {
      flex-direction: column;
    }
  }
}
