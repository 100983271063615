@import 'styles/settings.scss';

.c-time-away-approvals {
  height: 100%;

  &__list {
    height: 100%;
    width: 100%;
    padding: $spacing-l 0 $spacing-l $spacing-xl;

    display: flex;
    flex-direction: column;

    background-color: $color-white;
    border-radius: $radius-s;
  }

  &__list-header {
    padding-bottom: $spacing-l;
    margin-right: $spacing-xl;

    display: grid;
    grid-template-columns: 2fr 2.5fr 1.5fr 2.3fr 1.7fr;
    align-items: center;
    gap: $spacing-2xs;

    color: $color-gray-7;
    font-size: $fs-small;
    font-weight: $fw-bold-light;

    border-bottom: 1px solid $color-gray-7;
  }

  &__items-container {
    height: 100%;

    overflow: auto;
    @include custom-scrollbar;
  }

  &__item-container {
    padding: $spacing-l $spacing-5xs;
    width: 100%;

    display: grid;
    grid-template-columns: 2fr 2.5fr 1.5fr 2.3fr 1.7fr;
    align-items: center;
    gap: $spacing-2xs;

    border-bottom: 1px solid $color-light-gray-new-1;

    &--missing-data {
      padding: $spacing-l 0;
      color: $color-gray-3;
      font-weight: $fw-base;
    }
  }

  &__item {
    font-size: $fs-xsmall;
    color: $color-dark-gray-new-2;
    font-weight: $fw-medium;

    &--link {
      cursor: pointer;
      color: $color-dark-gray;
      font-weight: $fw-semi-bold;

      &:hover {
        color: $color-secondary;
      }
    }

    &--missing-data {
      color: $color-light-gray-new-3;
    }
  }

  &__date-range {
    width: 80%;
    display: grid;
    grid-template-columns: 40% 15% 45%;
    align-items: center;

    &--header {
      grid-template-columns: 55% 45%;
    }
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-l;

    button {
      flex: 1;
      border-radius: $radius-s;
    }
  }
}
