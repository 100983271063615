@import 'styles/settings.scss';

.c-time-away-actions {
  background-color: $color-white;
  &__container {
    display: flex;
    gap: $spacing-xl;
    background-color: $color-white;

    border-radius: $radius-xs;
  }

  &__icon {
    cursor: pointer;
    width: 18px;
    height: 18px;

    path {
      width: 17px;
      height: 17px;
      fill: $color-gray-9;
    }

    &:hover {
      path,
      rect {
        fill: $color-secondary;
        transition: fill 0.2s ease-in-out;
      }
    }

    &--selected {
      path,
      rect {
        fill: $color-secondary;
      }
    }
  }
}
