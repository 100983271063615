@import 'styles/settings.scss';

.c-offboarding-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: $spacing-l;

  &__field-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $spacing-2xs;
    padding-bottom: $spacing-5xs;

    label {
      font-size: $fs-small;
      font-weight: $fw-semi-bold;
      color: $color-dark-gray;
    }
  }

  &__button-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-top: $spacing-s;
  }
}
