@import 'styles/settings.scss';

.c-day-picker {
  display: flex;
  gap: $spacing-l;
  justify-content: space-between;
  align-items: center;

  background-color: $color-gray-11;
  border-radius: $radius-s;
  padding: 0 30px;

  &__button-holder {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  &__month {
    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-gray-7;
  }

  &__current-date {
    font-size: $fs-base;
    font-weight: $fw-bold-light;
    color: $color-primary;
  }

  &__month-text {
    font-size: $fs-base;
    font-weight: $fw-bold-light;
    color: $color-gray-7;
  }

  &__month-year-picker {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    z-index: 2;
    margin-top: $spacing-2xs;

    border-radius: $radius-xs;
    background-color: $color-white;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -15px;
      width: 100%;
      height: 20px;
      z-index: 999;
    }
  }

  &__button {
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px $spacing-xs;

    color: $color-gray-7;
    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;

    cursor: pointer;

    path {
      fill: $color-white;
    }

    background-color: $color-white;
    border-radius: $radius-xs;
    border: 1px solid $color-gray-3;

    &:hover,
    &--expanded {
      .c-day-picker__button-arrow {
        path {
          fill: $color-secondary;
        }
      }
    }

    &--expanded {
      .c-day-picker__button-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__button-arrow {
    transition: transform $transition-1;

    path {
      height: 8px;
      width: 7px;
      fill: $color-gray-3;
    }
  }

  &__picker {
    flex: 0 0 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-l;
    height: 100%;
  }

  &__days {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__day {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;

    padding: 10px 0;

    font-size: $fs-small;
    font-weight: $fw-base;
    color: $color-gray-7;
    z-index: 0;

    &:hover {
      background: rgba(225, 241, 255, 0.4);
    }

    &--weekend {
      color: $color-gray-3;
    }

    &--hovered {
      background: rgba(225, 241, 255, 0.4);
    }
  }

  &__icon {
    &:hover {
      cursor: pointer;
      path {
        fill: $color-secondary;
      }
    }
  }
}
