@import 'styles/settings.scss';

.c-workflow-templates {
  display: flex;
  flex-direction: column;

  height: 100%;

  background-color: $color-white;

  &__cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-s;
    padding: $spacing-l 0 $spacing-l $spacing-xl;

    overflow: auto;
    @include custom-scrollbar;
  }

  &__header {
    margin: $spacing-l $spacing-l 0 auto;
  }

  &__add-button-icon {
    path {
      fill: $color-secondary;
    }
  }
}
