@import 'styles/settings.scss';

.c-filters-button {
  &__wrap {
    &:hover {
      .c-filters-button__icon {
        rect,
        circle {
          fill: $color-secondary !important;
        }
      }
    }
  }

  &__icon {
    rect,
    circle {
      transition: fill $transition-1;
      fill: $color-light-gray-new-3 !important;
    }

    &--active {
      rect,
      circle {
        fill: $color-secondary !important;
      }
    }
  }
}
