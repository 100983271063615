@import 'styles/settings.scss';

.c-users-overview {
  &__content {
    padding: $spacing-5xs;
    padding-right: 0 !important;
    padding-top: 0.6rem;
    display: grid;
    row-gap: $spacing-l;
    overflow: auto;
    @include custom-scrollbar;

    &--small-plus {
      grid-template-columns: repeat(5, 1fr);

      @media (min-width: 1500px) {
        grid-template-columns: repeat(6, 1fr);
      }
      @media (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 990px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--large {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  &__report {
    position: relative;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
  }

  &__name {
    display: flex;
    flex-direction: column;

    span {
      color: $color-gray-7;
      font-size: $fs-small;
      font-weight: $fw-bold-light;
    }
  }

  &__add-people {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: $spacing-l;

    span {
      color: $color-gray;
      font-size: $fs-small;
      font-weight: $fw-bold-light;
      text-align: center;
    }

    &-icon {
      width: $spacing-7xl;
      height: $spacing-7xl;

      path {
        fill: $color-gray;
      }
    }

    &:hover {
      span {
        color: $color-dark-gray-new-1;
      }

      .c-users-overview__add-people-icon {
        rect {
          fill: $color-primary;
        }

        circle {
          stroke: $color-dark-gray-new-1;
        }
      }
    }
  }
}
