@import 'styles/settings.scss';

.c-input {
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;

  &__input-label {
    padding-bottom: $spacing-2xs;
    color: $color-dark-gray-new-2;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;

    &--required {
      &::after {
        content: '*';
      }
    }
  }

  &__optional-text {
    font-weight: 100;
  }

  &__field-holder {
    display: flex;
    align-items: center;
    width: inherit;
  }

  &__field {
    width: 100%;
    min-height: 2.063rem;

    padding: $spacing-4xs $spacing-s;

    font-weight: $fw-bold-light;
    font-size: $fs-small;
    color: $color-dark-gray-new-2;

    border-radius: $radius-xs;
    border: 1px solid $color-gray-9;

    &:focus {
      outline: none;
      background-color: $color-gray-5;
      border-color: $color-gray-5;
    }

    &--align-right {
      text-align: right;
    }

    &--medium-width {
      width: 80%;
    }

    &--small {
      font-size: $fs-xsmall;
    }

    &--medium {
      padding: $spacing-3xs $spacing-s !important;
      font-size: $fs-small;
      font-weight: $fw-semi-bold;

      .c-input__input-label {
        font-size: $fs-small;
        font-weight: $fw-semi-bold;
        letter-spacing: $letter-spacing-m;
      }
    }

    &--large {
      padding: $spacing-4xs $spacing-xs;
      font-size: $fs-base;
      font-weight: $fw-bold-light;
    }

    &--xl {
      padding: $spacing-xs $spacing-l !important;
      font-weight: $fw-semi-bold;
    }

    &--gray-border {
      border-color: $color-light-gray-new-3;
    }

    &--error-border {
      border-color: $color-error;
    }

    &--read-only {
      pointer-events: none;
      border-color: transparent;
      background: transparent;

      white-space: nowrap;
      text-overflow: ellipsis;

      &:focus {
        outline: none;
      }
    }

    &--invalid {
      outline: 1px solid $color-red;
    }

    &--calendar {
      cursor: pointer;
      padding: $spacing-4xs $spacing-s;
    }
  }

  &__error-message {
    color: $color-error;
    margin-top: $spacing-2xs;
    font-size: $fs-xxsmall;

    &--right {
      margin-top: 0;
      display: flex;
      align-items: center;

      margin-left: $spacing-l;
    }

    &--xl {
      font-size: $fs-xsmall;
    }
  }

  &__icon {
    margin-left: $spacing-l;

    &:hover {
      cursor: pointer;

      path {
        fill: $color-secondary;
      }
    }
  }

  &__calendar-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.75rem;

    path {
      fill: $color-light-gray-new-2;
    }
  }

  &:hover {
    .c-input__calendar-icon {
      path {
        fill: $color-secondary;
      }
    }
  }
  &__calendar {
    position: absolute;
    top: 100%;
    z-index: 1;
    box-shadow: 6px 8px 24px rgba(0, 0, 0, 0.2);
    background-color: $color-white;

    &--overflow-with-label {
      top: unset;
      bottom: 65%;
    }

    &--right {
      left: 0;
    }

    &--left {
      right: 0;
    }

    &--center {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__required-icon {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__required-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    position: absolute;
    bottom: $spacing-l;

    width: 110px;

    padding: 2px $spacing-2xs;
    margin-left: $spacing-xl;

    color: $color-white;
    font-size: $fs-xsmall;

    border-radius: 5px;
    background-color: $color-dark-gray;
  }

  ::placeholder {
    font-weight: $fw-base;
    font-size: $fs-xxsmall;
    color: $color-gray-2;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type='date'][value='']:not(.c-input__field--calendar-opened) {
    color: transparent;
  }
}
