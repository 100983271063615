@import 'styles/settings.scss';

.c-timesheets-overview {
  height: 100%;
  padding: $spacing-xl $spacing-l;

  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;

  &__list-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    background: $color-white;
    padding: $spacing-l;
    border-radius: $radius-s;

    overflow: auto;
  }

  &__list-header {
    display: grid;
    grid-template-columns: auto repeat(7, 1fr);
    column-gap: $spacing-4xl;

    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-gray-7;

    padding: 0 $spacing-xl $spacing-2xs 0;
    border-bottom: 1px solid $color-dark-gray-new-2;
  }

  &__list {
    flex: 1;
    overflow: auto;
    @include custom-scrollbar;
  }

  &__missing-data-message {
    display: block;
    margin: $spacing-xl 0 0 $spacing-2xs;

    font-size: $fs-xsmall;
    color: $color-gray-2;
  }
}
