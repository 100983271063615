@import 'styles/settings.scss';

.c-sidebar-item {
  &__link {
    padding: $spacing-xs $spacing-2xs;
    display: flex;
    align-items: center;

    color: $color-gray;

    &:hover,
    &--active {
      background-color: $color-light-gray-new-4;
      border-radius: $radius-s;

      .c-sidebar-item__icon {
        path {
          fill: $color-primary;
        }
      }
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin: 0 $spacing-2xs;

    display: flex;
    align-items: center;

    path {
      fill: $color-gray;
    }
  }

  &__label {
    font-weight: $fw-medium;
    font-size: $fs-xsmall;
    color: $color-dark-gray-new-2;
  }
}
