@import 'styles/settings.scss';

.c-toggle-button {
  display: inline-block;

  &__label {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 26px;
    font-size: 16px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 26px;

    &::before {
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      left: $spacing-5xs;
      bottom: $spacing-5xs;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

input:checked + .c-toggle-button__slider {
  background-color: $color-ocean-blue;
}

input:focus + .c-toggle-button__slider {
  box-shadow: 0 0 1px $color-ocean-blue;
}

input:checked + .c-toggle-button__slider:before {
  transform: translateX(22px);
}
