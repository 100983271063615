@import 'styles/settings.scss';

.c-section-secondary {
  display: flex;
  flex-direction: column;

  padding: $spacing-xs $spacing-xl;

  border-radius: $radius-s;

  font-family: $font-family;
  font-size: $fs-xsmall;
  color: $color-dark-gray-new-2;
  font-weight: $fw-bold-light;

  background: $color-white;

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: $spacing-4xl;
    min-height: $spacing-4xl;
    margin-bottom: $spacing-xl;
  }

  &__title {
    font-size: $fs-base;
    color: $color-dark-gray-new-2;
    font-weight: $fw-bold-light;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__info-header {
    display: grid;
    gap: $spacing-xl;

    padding-bottom: $spacing-l;

    border-bottom: 1.2px solid $color-gray-7;

    &--small {
      grid-template-columns: repeat(5, 1fr);
    }

    &--medium {
      grid-template-columns: repeat(5, 1fr);
    }

    &--large {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__info-header-title {
    font-size: $fs-xsmall;
    color: $color-gray-7;
    font-family: $font-family;
    font-weight: $fw-bold-light;
  }

  &__info-data {
    min-height: $spacing-6xl;
    display: grid;
    align-items: center;
    gap: $spacing-xl;

    padding: $spacing-xs 0;

    font-size: $fs-small;
    color: $color-dark-gray;
    font-family: $font-family;
    font-weight: $fw-medium;

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-5;
    }

    &--small {
      grid-template-columns: repeat(5, 1fr);
    }

    &--medium {
      grid-template-columns: repeat(5, 1fr);
    }

    &--large {
      grid-template-columns: repeat(6, 1fr);
    }

    &--edit-mode {
      min-height: $spacing-6xl;
      padding: $spacing-5xs 0;
    }
  }

  &__more {
    text-align: right;
    color: black;
  }

  &__data {
    display: flex;
    align-items: center;

    color: $color-gray-7;
    font-size: $fs-xsmall;
    vertical-align: middle;
    font-weight: $fw-medium;

    &--secondary {
      color: $color-dark-gray;
      font-size: $fs-small;
      font-family: $font-family;
      font-weight: $fw-semi-bold;
    }

    &--missing {
      color: $color-gray;
      font-size: $fs-xsmall;
      font-weight: $fw-semi-bold;
    }
  }

  &__missing-data-wrap {
    padding: $spacing-l 0;
  }

  &__user {
    display: flex;
    align-items: center;
    font-size: $fs-xsmall;
  }

  &__edit-part {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
  }
}
