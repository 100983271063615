@import 'styles/settings.scss';

.c-tooltip {
  padding: $spacing-m $spacing-l;
  width: max-content;
  background-color: $color-dark-gray-new-1;
  border-radius: $radius-xs;
  position: absolute;
  z-index: 1000;

  font-weight: $fw-bold-light;
  font-size: $fs-xxsmall;
  color: $color-white;

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  &--black {
    background-color: $color-black;
    color: $color-white;
    padding: $spacing-2xs $spacing-2xs;
  }
  &--top {
    top: 0;
    left: 50%;
    transform: translate(-50%, -105%);
  }

  &--bottom {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 105%);
  }

  &--right {
    right: 0;
    top: 50%;
    transform: translate(105%, -50%);
  }

  &--left {
    left: 0;
    top: 50%;
    transform: translate(-105%, -50%);
  }
}
