@import 'styles/settings.scss';

$icon-path: '../../../../../../../src/assets/CheckMark.svg';

.c-color-selector {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  &--selected {
    &::after {
      content: url($icon-path);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;
      font-size: $fs-base;
    }
  }
}
