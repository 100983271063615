@import 'styles/settings.scss';

.c-away-user {
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: center;
  gap: $spacing-2xs;
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    z-index: 1;

    height: 1px;
    background-color: $color-gray-3;
    width: 100%;
  }

  &__user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-2xs;
    padding: 6px 0;

    width: 100%;

    &--first {
      padding-top: calc(16px);
    }
  }

  &__about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-5xs;

    overflow: hidden;
  }

  &__aways {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    width: calc(100% - 70px - 2rem);
    height: 100%;
    position: relative;
    margin-right: 23px;
  }

  &__splited-away {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__day {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    padding: $spacing-2xs 0;

    font-size: $fs-small;
    font-weight: $fw-base;
    color: $color-gray-3;

    &:hover {
      background: rgba(225, 241, 255, 0.4);
    }

    &--hovered {
      background: rgba(225, 241, 255, 0.4);
    }

    &--weekend {
      background: $color-gray-11;
    }
  }

  &__current-day-horizontal {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: $color-primary;
    transform: translateX(-50%);
    z-index: 2;
  }

  &__away-bar {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: $color-gray;
    position: absolute;

    &--first {
      top: 20px;
    }

    &:hover {
      cursor: default;
    }
  }

  &__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $color-red;
    color: $color-white;
    position: relative;
  }

  &__away-reason {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    width: inherit;
    position: absolute;
    z-index: 1;
  }

  &__away-reason-text {
    padding: $spacing-5xs $spacing-l;

    white-space: nowrap;
    overflow: hidden;

    text-overflow: ellipsis;
    color: $color-white;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
  }

  &__hovered {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: absolute;
    top: 105%;
    width: fit-content;
    z-index: 2;
    padding: $spacing-2xs;

    color: $color-white;
    white-space: nowrap;
    font-size: $fs-base;

    border-radius: $spacing-2xs;
    background-color: $color-dark-gray;

    span {
      display: flex;
      align-items: center;
    }
  }

  &--placeholder {
    height: 100%;
  }
}
