@import 'styles/settings.scss';

.c-number-input {
  height: 100%;

  display: flex;
  align-items: center;

  &__value {
    font-weight: $fw-base;
    font-size: $fs-small;
    color: $color-gray-2;
  }

  &__button {
    height: 24px;
    width: 24px;

    background: $color-light-gray-new-5;

    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;

    cursor: pointer;

    path {
      fill: $color-secondary;
    }
  }

  &__input-field {
    max-width: 25px;
    margin: 0 $spacing-2xs;

    text-align: center;
    font-weight: $fw-bold-light;
    font-size: $fs-small;
    color: $color-dark-gray;
    letter-spacing: $letter-spacing-m;
    border: none;
    outline: none;
  }
}
