@import 'styles/settings.scss';

.c-year-picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;

  &__dropdown-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-2xs;
  }

  &__dropdown-label {
    font-size: $fs-xsmall;
    color: $color-dark-gray;
    font-weight: $fw-medium;
    width: 50%;
    white-space: nowrap;
  }
}
