@import 'styles/settings.scss';

.c-section {
  display: flex;
  flex-direction: column;
  min-height: 392px;

  @include breakpoint(desktop-xxl) {
    min-height: 402.75px;
  }

  @include breakpoint(desktop-xxxl) {
    min-height: 413.75px;
  }

  padding: $spacing-l $spacing-xl $spacing-l $spacing-2xs;

  background-color: $color-white;

  border-radius: $radius-s;

  transition: box-shadow $transition-1;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-2xs;
    margin-bottom: $spacing-2xs;
    padding-right: $spacing-3xs;
  }

  &__header-title {
    flex: 1;
  }

  &__draggable-icon {
    &:hover {
      cursor: grab;
      path {
        fill: $color-secondary;
      }
    }
    &:active {
      cursor: grabbing;
      path {
        fill: $color-secondary;
      }
    }
    outline: none;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;

    margin-left: $spacing-s;
    border-top: 1px solid $color-dark-gray-new-2;

    &--missing-data {
      padding: $spacing-xl 0;
      font-size: $fs-xsmall;
      color: $color-gray;
    }
  }

  &__content-header {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr 0.1fr;

    padding-bottom: $spacing-2xs;

    color: $color-gray-2;
    font-weight: $fw-base;
    font-size: $fs-small;

    border-bottom: 1px solid $color-gray;
  }

  &__header-dates {
    display: flex;
    flex-direction: row;
    gap: $spacing-2xs;

    width: 90%;
  }

  &__header-date {
    width: 50%;
  }

  &__edit-icon {
    height: 18px;

    path {
      fill: $color-gray-3;
    }

    &:hover {
      cursor: pointer;

      path {
        fill: $color-secondary;
      }
    }
  }

  &__icon {
    path {
      fill: $color-gray-3;
    }
    &:hover {
      path {
        fill: $color-secondary;
      }
      cursor: pointer;
    }

    &--disabled {
      pointer-events: none;
      path {
        fill: $color-gray;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: $spacing-3xs;
    margin-top: auto;
    margin-left: $spacing-s;
    border-top: 1px solid $color-dark-gray-new-2;
  }

  &__button-content {
    display: flex;
    align-items: center;
    gap: $spacing-5xs;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new-2;
  }

  &__button-content-icon {
    height: $spacing-s !important;
    width: $spacing-s !important;

    path {
      fill: $color-primary;
    }

    &--expanded {
      transform: rotate(180deg);
    }
  }

  &--expanded {
    box-shadow: $box-shadow-3;
  }
}
