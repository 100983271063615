@import 'styles/settings.scss';

.c-add-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-3xs;

  padding: $spacing-4xs 0;
  color: $color-light-gray-new-3;
  font-size: $fs-xsmall;
  font-weight: $fw-bold-light;

  svg {
    height: 24px;
    width: 25px;
  }

  path {
    fill: $color-light-gray-new-3;
  }

  circle {
    stroke: $color-light-gray-new-3;
  }

  cursor: pointer;

  &:not(:disabled) {
    &:hover {
      color: $color-dark-gray-new-2;

      path {
        fill: $color-dark-gray-new-2;
      }

      circle {
        stroke: $color-primary;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    color: $color-light-gray-new-4;

    path {
      fill: $color-light-gray-new-4;
    }

    circle {
      stroke: $color-light-gray-new-4;
    }
  }
}
