@import 'styles/settings.scss';

.c-calendar-form {
  &__checkbox-holder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__public-holidays {
    grid-column: span 2;
  }

  &__public-holidays-header {
    display: flex;
    align-items: center;
    gap: $spacing-s;
    margin: $spacing-xl 0;

    &--modal {
      margin: $spacing-2xs 0 $spacing-xl;
      justify-content: space-between;

      circle {
        fill: $color-primary !important;
      }

      .c-calendar-form__public-holidays-heading {
        font-size: $fs-base-xl;
        color: $color-dark-gray;
        letter-spacing: 0.36px;
      }
    }
  }

  &__public-holidays-heading {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    white-space: nowrap;
  }

  &__add-public-holiday-icon {
    circle {
      fill: $color-gray;
    }
  }

  &__public-holidays-content {
    padding-bottom: $spacing-xl;

    grid-column: span 2;

    @include custom-scrollbar;

    &--modal {
      display: flex;
      flex-direction: column;
      gap: $spacing-2xl;
      padding-bottom: 0;

      .c-calendar-form__public-holiday-item {
        &:not(:last-child) {
          padding-bottom: $spacing-xl;
          border-bottom: 1px solid $color-light-gray-new-1;
        }
      }
    }
  }

  &__missing-content-message {
    margin: $spacing-xl auto;
    font-size: $fs-xsmall;
    color: $color-gray;
  }

  &__public-holiday-item {
    display: flex;
    flex-direction: column;
    gap: $spacing-l;
  }

  &__item-inputs-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-4xl;
    grid-column: span 2;

    &--modal {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: $spacing-s;
      padding-bottom: $spacing-2xl;

      border-bottom: 1px solid $color-light-gray-new-1;

      .c-calendar-form__checkbox-holder {
        justify-content: flex-start;
      }
    }
  }

  &__public-holiday-add-button {
    margin-top: $spacing-4xl;
  }

  &__public-holiday-item-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 $spacing-4xl;
  }
}
