@import 'styles/settings.scss';

.c-signin {
  padding: 32px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  position: relative;
  background-color: $color-white;
  border-radius: 42px;

  &__form-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  }

  &__options {
    width: 100%;

    margin-top: 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(phone) {
      flex-direction: column;
      gap: $spacing-xl;
    }

    @include breakpoint(tablet) {
      flex-direction: column;
      gap: $spacing-xl;
    }
  }

  &__options-item {
    display: inline-block;

    cursor: pointer;

    font-size: $fs-xsmall;
    letter-spacing: $letter-spacing-m;
    color: $color-light-gray-new-3;
    font-family: $font-family;
    font-weight: $fw-semi-bold;

    &--fp {
      position: relative;
      text-align: right;
      white-space: nowrap;

      &::after {
        content: '';

        display: block;
        height: 1px;
        background-color: $color-light-gray-new-3;
      }
    }

    &--link {
      display: block;
      width: 100%;

      font-weight: $fw-bold-light;
      color: $color-dark-gray;
      text-align: center;
      font-size: $fs-small;

      border: 1px solid $color-gray;
      border-radius: $spacing-l;

      padding: 14px 0;

      &:hover {
        cursor: pointer;
      }

      &:visited {
        color: $color-gray-2;
      }
    }

    &--checked {
      color: $color-dark-gray;
    }
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    gap: $spacing-4xs;
    cursor: pointer;

    svg {
      height: 16px;
      width: 16px;
    }

    &--checked {
      rect {
        fill: $color-secondary;
      }
    }

    &-circle {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 1px solid $color-gray;
    }
  }

  &__checkbox {
    width: 20px;
    height: 20px;

    margin-right: $spacing-5xs;
    color: #0000001a;
  }

  &__button-container {
    width: 100%;
    margin-top: 24px;
  }

  &__option-link {
    font-family: $font-family;
    font-weight: $fw-semi-bold;
    color: $color-gray-2;

    &:hover {
      cursor: pointer;
    }

    &:visited {
      color: $color-gray-2;
    }
  }

  &__horizontal-line {
    height: 1px;
    width: 100%;
    background-color: $color-gray-9;
  }

  &__horizontal-line-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__horizontal-line-span {
    margin: $spacing-l $spacing-xs;

    font-family: $font-family;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
    color: $color-dark-gray;
  }

  &__buttons-container {
    width: 100%;
    margin-top: $spacing-xs;
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
  }

  &__signin-button-text {
    font-family: $font-family;
    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-dark-gray;

    display: flex;
    align-items: center;
    gap: $spacing-4xs;
  }
}
