@import 'styles/settings.scss';

.c-input-container {
  width: 100%;

  margin-top: $spacing-xs;

  display: flex;
  flex-direction: column;

  &__input-label {
    padding-bottom: $spacing-5xs;

    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: $fs-medium;
    line-height: 24px;
    color: $color-gray-2;
  }

  &__input-wrap {
    position: relative;
    width: 100%;
  }

  &__password-visibility {
    position: absolute;
    right: $spacing-2xs;
    top: 50%;
    transform: translateY(-50%);

    &--pw {
      path {
        fill: $color-gray;
      }
    }
  }

  &__input-field {
    padding: 9px $spacing-l;
    margin-left: 0;
    width: 100%;

    background-color: $color-white;
    border: 1px solid $color-light-gray-new-3;
    border-radius: $radius-xs;

    font-family: $font-family;
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;

    &--invalid {
      outline: none;
      border: 1px solid $color-error;
    }

    &::placeholder {
      color: $color-light-gray-new-3;
    }
  }

  &__invalid-message {
    margin-top: $spacing-2xs;
    min-height: 10px;
    font-family: $font-family;
    font-size: $fs-xsmall;
    color: $color-error;
  }
}
