@import 'styles/settings.scss';

.c-workflow-template-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: $spacing-l;

  overflow-y: auto;
  @include custom-scrollbar;

  padding: $spacing-l 0 $spacing-l $spacing-xl;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-2xs;

    background-color: white;
    padding: $spacing-2xs;

    border-radius: $radius-s;
  }

  &__title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: $spacing-xl;

    color: $color-dark-gray;
    font-size: $fs-medium;
    font-weight: $fw-bold;
  }

  &__section-actions-wrap {
    display: flex;
    gap: $spacing-2xs;
  }

  &__missing-data {
    height: 15%;
    padding: $spacing-l;

    display: flex;
    justify-content: flex-start;

    background-color: $color-white;
    font-size: $fs-small;
    color: $color-gray-3;
    border-radius: $spacing-2xs;
  }

  &__sections-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
  }
}
