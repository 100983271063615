@import 'styles/settings.scss';

.c-form-container {
  width: 100%;
  border-radius: $spacing-2xs $spacing-2xs 0 0;
  padding: $spacing-2xs 0 $spacing-l;

  border-bottom: 1px solid $color-dark-gray-new-2;

  &--modal {
    border: none;

    .c-form-container__content {
      display: flex;
      flex-direction: column;
      gap: $spacing-s;
    }
  }

  &--with-actions {
    background-color: transparent;
    padding: 0;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10%;
    overflow: visible;
  }

  &__actions {
    display: flex;
    justify-content: center;

    margin-top: $spacing-2xl;
  }
}
