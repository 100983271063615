@import 'styles/settings.scss';
.c-icon-button-wrapper {
  position: relative;
}
.c-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  cursor: pointer;

  path {
    fill: $color-light-gray-new-3;
  }

  circle {
    stroke: $color-light-gray-new-3;
  }

  ellipse,
  path,
  rect,
  circle {
    transition: all $transition-1;
  }

  &:not(:disabled, &--warning) {
    &:hover {
      path {
        fill: $color-primary;
      }

      circle {
        stroke: $color-primary;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      path {
        fill: $color-light-gray-new-4;
        stroke: $color-light-gray-new-4;
      }

      circle {
        stroke: $color-light-gray-new-4;
      }
    }
  }

  &--medium {
    svg {
      width: 17px;
      height: 17px;
    }
  }

  &--large {
    svg {
      width: 21px;
      height: 21px;
    }
  }

  &--secondary {
    &:not(:disabled) {
      &:hover {
        path,
        rect {
          fill: $color-secondary;
        }

        circle {
          stroke: $color-secondary;
        }
      }
    }
  }

  &--secondary-selected {
    path,
    rect {
      fill: $color-secondary;
    }

    circle {
      stroke: $color-secondary;
    }

    &:not(:disabled) {
      &:hover {
        path,
        rect {
          fill: $color-secondary;
        }

        circle {
          stroke: $color-secondary;
        }
      }
    }
  }

  &--filter-close {
    path {
      fill: $color-light-gray-new-5;
    }

    ellipse {
      fill: $color-light-gray-new-3;
    }

    &:not(:disabled) {
      &:hover {
        path {
          fill: $color-dark-gray-new-2;
        }

        ellipse {
          fill: $color-light-gray-new-5;
        }
      }
    }
  }

  &--close {
    &:not(:disabled) {
      &:hover {
        ellipse {
          fill: $color-dark-gray;
        }
        path {
          fill: $color-light-gray-new-2;
        }
      }
    }
  }

  &--delete {
    &:not(:disabled) {
      &:hover {
        path {
          fill: $color-error;
        }

        circle {
          stroke: $color-error;
        }
      }
    }
  }

  &--warning {
    path {
      fill: $color-secondary;
    }
  }
}
