@import 'styles/settings.scss';

.c-registration {
  padding: 32px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background-color: $color-white;
  border-radius: 42px;

  &__button-container {
    width: 100%;
    margin-top: $spacing-5xl;
  }
}
