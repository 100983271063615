@import 'styles/settings.scss';

.c-section-info-read-only {
  display: flex;
  flex-direction: column;

  &__data-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: $spacing-xs 0;
  }

  &__data {
    display: flex;
    align-items: center;
    width: 100%;

    color: $color-light-gray-new-3;
    font-size: $fs-xsmall;

    padding-right: 13px;
    vertical-align: middle;

    font-family: $font-family;
    font-weight: $fw-base;

    &--secondary {
      justify-content: flex-end;

      color: $color-dark-gray-new-2;
      font-weight: $fw-bold-light;
      white-space: nowrap;
    }

    svg {
      height: 16px;
      width: 16px;
    }

    path {
      fill: $color-primary;
    }
  }

  &__missing-info-holder {
    background-color: $color-orange-1;
    border-radius: $radius-xxs;
    padding: 4px $spacing-xs;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    font-size: $fs-xxsmall;
    color: $color-white;
  }

  &__dropdown-container {
    width: 80%;
  }

  &__label {
    margin-left: $spacing-s;
    font-size: $fs-xsmall;
    color: $color-gray-10;
    font-weight: $fw-medium;
    white-space: nowrap;
  }

  &__buttons-container {
    padding: $spacing-2xs 0$spacing-5xs;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-gray-5;
  }
}
