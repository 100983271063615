@import 'styles/settings.scss';

*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: $font-family;
  font-size: $fs-base;
  font-weight: $fw-base;
  font-style: normal;
  line-height: 1;

  @media only screen and (min-width: 1921px) {
    font-size: 17px;
  }

  @media only screen and (min-width: 2240px) {
    font-size: 18px;
  }
}

input {
  font-family: $font-family;
  font-size: $fs-small;
  font-weight: $fw-base;
}

img {
  max-width: 100%;
}
