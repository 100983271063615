@import 'styles/settings.scss';

.c-public-holiday {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &__content {
    position: relative;
    flex: 1;
  }

  &__icon {
    position: absolute;

    height: 50px;
    width: 50px;
    left: -5px;

    stroke-width: 0.4;
  }
}
