@import 'styles/settings.scss';

.c-spinner {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
