@import 'styles/settings.scss';

.c-profile {
  display: flex;

  height: calc(100vh - $toolbar-height);
  padding: $spacing-l 0 $spacing-l $spacing-xl;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-l;
    height: 100%;
    width: 100%;
    position: relative;

    overflow: auto;
    @include custom-scrollbar;
  }

  &__modal-content {
    width: 100%;
  }

  &__input {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &__confirmation-buttons-wrap {
    display: flex;
    justify-content: space-between;
    padding: 0 $spacing-4xl;
    gap: $spacing-xl;
  }

  &__modal-content-picture-preview {
    min-width: 300px;
    min-height: 300px;
  }

  &__modal-content-picture {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__modal-content-picture-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto $spacing-6xl auto;
    border-radius: 50%;
  }

  &__modal-content-actions {
    max-width: 50%;
  }

  &__canvas {
    object-fit: contain;
    width: 150px;
    height: 150px;
    display: none;
  }
}
