@import 'styles/settings.scss';

.c-custom-field-modal {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: $spacing-s;

  &__dropdown-label {
    display: inline-block;

    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;
    letter-spacing: $letter-spacing-m;
  }

  &__dropdown-option {
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    letter-spacing: 0.14px;
    color: $color-dark-gray;
  }

  &__input-container {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
  }

  &__button-container {
    margin-top: $spacing-xl;
    display: flex;
    justify-content: center;
  }

  &__dropdown-info {
    font-size: $fs-xxsmall;
    color: $color-light-gray-new-3;
  }
}
