@import 'styles/settings.scss';

.c-badge {
  padding: $spacing-5xs $spacing-2xs;

  width: fit-content;

  display: flex;
  align-items: center;

  font-family: $font-family;
  font-weight: $fw-base;
  font-size: $fs-base;
  line-height: 17px;
  letter-spacing: 0.01rem;

  border-radius: $spacing-5xs;

  &--small {
    padding: 0 $spacing-2xs;

    .c-badge__content {
      font-size: $fs-xxsmall;
      font-weight: $fw-medium;
    }
  }

  &--medium {
    padding: $spacing-5xs $spacing-2xs;
    font-size: $fs-small;
  }

  &--medium-plus {
    padding: $spacing-2xs $spacing-m;

    font-size: $fs-small;
    font-weight: $fw-bold;
  }

  &--blue {
    background-color: $color-light-blue;
    color: $color-blue;

    path {
      fill: $color-blue;
    }
  }

  &--orange {
    background-color: $color-secondary;
    color: $color-white;
    path {
      fill: $color-white;
    }
  }

  &--orange-rounded {
    color: $color-orange-dark;
    background-color: $color-orange-light;

    border-radius: $radius-m;
  }

  &--yellow {
    margin: 40px 0;
    font-size: $fs-small;
    font-weight: $fw-bold;
    color: #a38c35;
    line-height: 16px;
    text-align: left;
    background-color: #f9efce;
    width: fit-content;
    padding: 7px 14px;
    border-radius: 20px;
    margin: 0;
  }

  &--bold {
    span {
      font-weight: $fw-semi-bold;
    }
  }
}
