// Old colors
$primary-color: #0073de;
$color-white: #fff;
$color-white-2: #f0f0f0;
$color-light-gray: #e0e0e0;
$color-gray: #bdbdbd;
$color-dark-gray: #3f4c4f;
$color-blue: #2495ff;
$color-light-blue: #e1f1ff;
$color-red: #ef7878;
$color-gray-11: #eff2f5;
$color-gray-10: #787878;
$color-gray-9: #dbdbdb;
$color-gray-8: #f3f3f3;
$color-gray-7: #373b3c;
$color-gray-6: #f7f9f9;
$color-gray-5: #f7f7f7;
$color-gray-3: #bac2cf;
$color-gray-2: #7b858c;
$color-gray-1: #828282;
$color-ocean-blue: #3ad2ff;
$color-purple: #c49dea;
$color-orange-1: #ffb761;

// Colors
$color-primary: #6727e2;
$color-primary-light: #977bcc;
$color-secondary: #ff9b24;
$color-logo: #0b2641;
$color-black: #000000;
$color-dark-gray-new: #2c2c2c;
$color-dark-gray-new-1: #393939;
$color-dark-gray-new-2: #373b3c;
$color-light-gray-new-1: #f7f7f7;
$color-light-gray-new-2: #dbdbdb;
$color-light-gray-new-3: #787878;
$color-light-gray-new-4: #f3f3f3;
$color-light-gray-new-5: #e6e6e6;
$color-light-gray-new-6: #e7e7e7;
$color-light-gray-new-7: #fcfcfc;
$color-light-gray-new-8: #f5f5f5;
$color-orange-dark: #ffa53a;
$color-orange-light: #ffebd3;

$color-success: #78c964;
$color-error: #eb5757;

// Fonts
$font-family: 'Nunito', sans-serif;
$fs-base: 16px;

$fs-xxsmall: 0.688rem; // 11px
$fs-xxsmall-plus: 0.713rem;
$fs-xsmall: 0.8rem; // 13px
$fs-small: 0.875rem; // 14px
$fs-base-s: 0.913rem;
$fs-base-l: 1.063rem; // 17px
$fs-base-xl: 1.188rem; // 19px
$fs-medium: 1.25rem; // 20px
$fs-medium-plus: 1.375rem; // 22px
$fs-large: 1.5rem; // 24px
$fs-xlarge: 1.775rem;
$fs-xlarge-plus: 2rem;
$fs-xxlarge: 2.25rem; // 36px

// Font weight
$fw-base: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold-light: 700;
$fw-bold: 800;
$fw-extra-bold: 900;

// Spacings
$spacing-5xs: 0.25rem; // 4px
$spacing-4xs: 0.313rem; // 5px
$spacing-3xs: 0.375rem; //6px
$spacing-2xs: 0.5rem; // 8px
$spacing-xs: 0.625rem; // 10px
$spacing-s: 0.75rem; // 12px
$spacing-m: 0.875rem; // 14px
$spacing-l: 1rem; // 16px
$spacing-xl: 1.25rem; // 20px
$spacing-2xl: 1.5rem; // 24px
$spacing-3xl: 1.75rem; // 28px
$spacing-4xl: 2rem; // 32px
$spacing-5xl: 2.5rem; // 40px
$spacing-6xl: 3rem; // 48px
$spacing-7xl: 3.75rem; // 60px
$spacing-8xl: 5rem; // 80px

$letter-spacing-s: 0.24px;
$letter-spacing-m: 0.28px;
$letter-spacing-xl: 0.44px;
$letter-spacing-xxl: 0.72px;

// Radius
$radius-xxs: 5px;
$radius-xs: 10px;
$radius-s: 15px;
$radius-m: 20px;
$radius-l: 25px;

$toolbar-height: 85px;

// Box shadow
$box-shadow-1: 0px 0px 4px 0px #00000040;
$box-shadow-2: -2px 2px 8px rgba(0, 0, 0, 0.2);
$box-shadow-3: 0 0 16px rgba(0, 0, 0, 0.16);
$box-shadow-4: 2px 2px 4px #00000033;
$box-shadow-5: 0px 2px 3px rgba(0, 0, 0, 0.1);

// Transitions
$transition-1: 0.2s ease-in-out;
