@import 'styles/settings.scss';

.c-user-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__user-icon {
    position: relative;

    display: flex;
    align-items: center;

    background-repeat: no-repeat;
    user-select: none;
    fill: currentColor;
    cursor: pointer;
  }

  &__dropdown {
    min-width: 180px;
    position: absolute;
    top: 102%;
    left: 100%;
    font-size: $fs-xsmall;
    background-color: $color-white;
    box-shadow: 1px 4px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: $radius-m;
    transform: translateX(-100%);
    z-index: 100;
    padding: $spacing-xs $spacing-4xs;
  }

  &__item-link {
    padding: $spacing-xs $spacing-xs;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-2xs;

    border-radius: $radius-s;
    color: $color-light-gray-new-3;
    font-size: $fs-xsmall;

    transition:
      color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;

    svg {
      width: 18px;
      height: 18px;
    }

    path {
      fill: $color-light-gray-new-3;
      transition: fill 0.3s ease-in-out;
    }

    &:visited {
      color: $color-light-gray-new-3;
    }

    &--logout {
      background-color: transparent;
      width: 100%;
      border: none;
      font-size: $fs-xsmall;
      color: $color-light-gray-new-3;
      cursor: pointer;
    }

    &:hover {
      background-color: $color-gray-8;
      color: $color-dark-gray-new-2;

      path {
        fill: $color-primary;
      }
    }
  }
}
