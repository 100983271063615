@import 'styles/settings.scss';

.c-form-link {
  letter-spacing: $letter-spacing-m;

  display: block;
  width: 100%;

  font-weight: $fw-bold-light;
  color: $color-dark-gray;
  text-align: center;
  font-size: $fs-small;

  border: 1px solid $color-gray;
  border-radius: $spacing-l;

  padding: 14px 0;

  cursor: pointer;

  &:hover {
    box-shadow: $box-shadow-4;
  }
}
