@import 'styles/settings.scss';

.c-timesheet {
  height: 100%;
  padding: $spacing-l 0 $spacing-l $spacing-l;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-l;

  overflow: auto;
  @include custom-scrollbar;

  &__header {
    width: 90%;
    display: flex;
    justify-content: flex-end;

    min-height: 32px;
  }

  &__table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: $radius-s;

    background-color: $color-white;

    width: 90%;
  }

  &__table-header-cell {
    border: 0.5px solid $color-light-gray-new-2;
    padding: $spacing-2xs;

    &:first-child {
      border-top-left-radius: $radius-s;
    }

    &:last-child {
      border-top-right-radius: $radius-s;
    }

    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new-2;
  }
}
