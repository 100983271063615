@import 'styles/settings.scss';

.c-sidebar-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--collapsed {
    align-items: center;

    margin-left: 0;
  }

  &__button-content {
    margin-left: $spacing-2xs;
    display: block;
    font-weight: $fw-bold-light;
    font-size: $fs-xsmall;
    padding: 0 $spacing-2xs;
    color: $color-gray-3;
  }

  &__button--collapsed {
    width: auto;
    padding: 12px;
    border-radius: 100px;

    animation: 1s ease-in-out 0s 1 fadeIn;

    &:hover {
      cursor: pointer;
      width: 100%;
      border-radius: 100;
      background-color: $color-primary-light;
    }
  }
  svg {
    width: 19px;
    height: 19px;

    path {
      fill: $color-gray-3;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
