@import 'styles/settings.scss';

.c-filter-overview {
  background-color: $color-white;
  border-radius: $spacing-2xs;

  &__top-section {
    display: flex;
    justify-content: space-between;
    padding: $spacing-l $spacing-l $spacing-2xs 0;

    border: 1px solid $color-light-gray-new-6;
  }

  &__data {
    width: 80%;
    display: flex;
  }

  &__minimize {
    margin-top: -$spacing-4xs;
  }

  &--applied-filters {
    .c-filter-overview__top-section {
      border-bottom: 0;
    }
  }
}
