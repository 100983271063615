@import 'styles/settings.scss';

.c-projects-container {
  width: 100%;
  height: 100%;
  padding: $spacing-l 0 $spacing-l $spacing-xl;

  display: flex;
  flex-direction: column;
  gap: $spacing-4xl;

  &__cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-s;

    overflow: auto;
    @include custom-scrollbar;
  }

  &__card {
    height: 100%;
    min-height: 150px;
  }

  &__icon {
    path {
      fill: $color-secondary;
    }
  }

  &__pagination-wrap {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }

  @include breakpoint(desktop-xxxl) {
    &__cards {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include breakpoint(tablet) {
    &__cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include breakpoint(phone) {
    &__cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
