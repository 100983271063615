@import 'styles/settings.scss';

.c-add-button {
  background-color: $color-white-2;
  border-radius: $radius-m;

  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: background-color $transition-1;
  position: relative;

  &__label-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__label {
    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new-2;

    margin-left: $spacing-xs;
  }

  &__info {
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-gray-2;
    text-align: center;

    width: 100%;
    max-width: 100%;
    display: block;
    align-self: flex-end;
    position: absolute;
    left: 50%;
    bottom: $spacing-l;
    transform: translateX(-50%);
  }

  &:hover {
    cursor: pointer;
    background-color: darken($color-white-2, 5%);
  }
}
