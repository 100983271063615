@import 'styles/settings.scss';

.c-selection-button {
  font-size: $fs-small;
  font-weight: $fw-bold-light;
  color: $color-light-gray-new-2;
  text-align: center;

  height: 60px;
  width: 50%;
  border-radius: $radius-s;
  border: 1px solid $color-light-gray-new-2;
  background-color: transparent;
  transition: border-color $transition-1, color $transition-1;
  cursor: pointer;

  &:hover {
    border-color: $color-primary;
    color: $color-dark-gray-new-2;
  }

  &--selected {
    border-color: $color-primary;
    color: $color-dark-gray-new-2;
  }
}
