@import 'styles/settings.scss';

.c-user-workflow-overview {
  &__workflow-content {
    padding: $spacing-l 0 $spacing-l $spacing-l;
    max-width: 70%;
  }

  &__header {
    padding: $spacing-xs $spacing-l;
    margin: 0 $spacing-xl $spacing-l 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $color-white;
    border-radius: $spacing-l;
  }

  &__header-text {
    color: $color-gray-7;
    font-size: $fs-base-l;
    font-weight: $fw-bold-light;
  }

  &__sections-list {
    max-height: 80vh;
    border-radius: $radius-s;

    overflow: auto;
    @include custom-scrollbar;
  }

  &__sections-list-item {
    margin-bottom: $spacing-l;
  }

  &__tooltip-content {
    padding: $spacing-2xs;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $spacing-l;

    position: relative;
  }

  &__actions-icon {
    &:hover {
      path {
        fill: $color-primary;
      }
    }
  }
}
