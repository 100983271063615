@mixin breakpoint($value) {
  @if $value == 'phone' {
    @media only screen and (min-width: 120px) and (max-width: 767px) {
      @content;
    }
  } @else if $value == 'tablet' {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $value == 'touch' {
    @media only screen and (min-width: 120px) and (max-width: 1024px) {
      @content;
    }
  } @else if $value == 'desktop' {
    @media only screen and (min-width: 1025px) and (max-width: 1440px) {
      @content;
    }
  } @else if $value == 'desktop-xl' {
    @media only screen and (min-width: 1440px) and (max-width: 1840px) {
      @content;
    }
  } @else if $value == 'desktop-xxl' {
    @media only screen and (min-width: 1840px) and (max-width: 2240px) {
      @content;
    }
  } @else if $value == 'desktop-xxxl' {
    @media only screen and (min-width: 2240px) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $value) {
      @content;
    }
  }
}

@mixin custom-scrollbar {
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 1.25rem;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background: lighten(#ff9b24, 20%);
      background-clip: content-box;
      border: 0.5rem solid transparent;
    }
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ff9b24;
    background-clip: content-box;
    border: 0.5rem solid transparent;
  }
}
