@import 'styles/settings.scss';

.c-timesheets-overview-header {
  padding: $spacing-xl $spacing-l;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  background-color: $color-white;
  border-radius: $radius-s;

  &__filters {
    width: 80%;

    display: flex;
    align-items: center;
    gap: $spacing-l;
  }

  &__filter-wrap {
    display: flex;
    flex-direction: column;
    gap: $spacing-2xs;

    flex: 1;

    color: $color-dark-gray;
    font-weight: $fw-semi-bold;
    font-size: $fs-xsmall;
  }
}
