@import 'styles/settings.scss';

.c-timesheets-list-item {
  display: grid;
  grid-template-columns: 20px repeat(7, 1fr);
  grid-column-gap: $spacing-m;

  padding: $spacing-l 0;
  border-bottom: 1px solid $color-light-gray-new-1;

  font-size: $fs-small;
  color: $color-dark-gray-new-2;

  &__dropdown-icon {
    height: 20px;
    width: 20px;
    transition: $transition-1;
    transform: rotate(270deg);
    &--non-display {
      visibility: hidden;
    }
    &--opened {
      transform: rotate(120deg);
    }
  }
  &--expanded {
    display: block;
    background-color: $color-light-gray-new-7;
  }

  &--non-expanded {
    display: none;
  }

  &__data {
    align-self: center;

    &--month {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &--project {
      grid-column: 5;
    }
    &--expanded {
      margin-left: 1px;
    }
  }
  &--project {
    padding: $spacing-4xs $spacing-2xs;

    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;
    color: #77529e;

    background-color: #ddd4e7;
    border-radius: $radius-s;
    margin-right: 5px;
    &:hover {
      &:not(.c-timesheets-list-item--last-project) {
        background-color: #d0c4de;
      }
    }
  }

  &--projects {
    max-height: 48px;

    position: relative;

    display: flex;
    align-items: center;
    gap: $spacing-2xs;
    flex-wrap: wrap;
  }
}
