@import 'styles/settings.scss';

.c-custom-field {
  &__left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-l;

    svg {
      height: $spacing-3xs;
      width: $spacing-3xs;
    }

    path {
      fill: $color-secondary;
    }
  }

  &__text {
    text-transform: capitalize;
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
  }
}
