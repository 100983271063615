@import 'styles/settings.scss';

.c-create-timesheet-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-4xl;

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-xl;
  }

  &__label {
    color: $color-dark-gray;
    font-weight: $fw-semi-bold;
    font-size: $fs-small;
  }
}
