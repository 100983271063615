@import 'styles/settings.scss';

.c-time-away-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-l;

    &:last-of-type {
      margin-bottom: $spacing-4xl;
    }
  }

  &__heading {
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    color: $color-dark-gray;
    margin-bottom: $spacing-xs;
    letter-spacing: $letter-spacing-m;
  }
}
