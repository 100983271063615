@import 'styles/settings.scss';

.c-add-people-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;

  &__search {
    display: flex;
    align-items: center;
    gap: $spacing-l;
    width: inherit;
    padding: $spacing-xl 0;

    border-bottom: 1px solid $color-gray;
  }

  &__user-list {
    display: flex;
    flex-direction: column;
    gap: $spacing-l;

    &-title {
      color: $color-dark-gray !important;
      font-size: $fs-xsmall !important;
      font-weight: $fw-semi-bold !important;
    }

    &-subtitle {
      font-weight: $fw-base !important;
      color: $color-light-gray-new-3 !important;
      font-size: $fs-xxsmall !important;
    }
  }

  &__users {
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding-top: $spacing-l;
    margin-bottom: $spacing-7xl;

    overflow-y: auto;
    @include custom-scrollbar;

    width: inherit;
    height: 250px;
  }

  &__no-users {
    color: $color-gray-3;
  }

  &__button-container {
    margin: 0 auto;
  }
}
