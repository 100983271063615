@import 'styles/settings.scss';

.c-workflow-template-card {
  padding: $spacing-xl $spacing-l;
  background-color: $color-white;
  border-radius: $radius-s;

  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
  transition: box-shadow $transition-1;

  &:hover {
    cursor: pointer;
    box-shadow: $box-shadow-5;
  }

  &__title {
    font-size: $fs-base;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new-2;
  }

  &__subtitle {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;
    color: $color-gray;
  }
}
