@import 'styles/settings.scss';

.c-section-item {
  height: 53px;
  padding: 14px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid $color-gray-5;
  }

  &--edit {
    border-color: $color-dark-gray-new-2 !important;
  }

  &__left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    margin-right: $spacing-s;
    path {
      fill: $primary-color;
    }
  }

  &__content {
    font-size: $fs-xsmall;
    color: $color-dark-gray-new-2;
    font-weight: $fw-bold-light;
    margin-right: $spacing-5xs;
  }

  &__item-option {
    font-weight: $fw-medium;
    font-size: $fs-xsmall;
    color: $color-gray;
  }

  &__item-badge {
    margin-left: $spacing-2xs;
  }

  &__right-container {
    display: flex;
    align-items: center;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $spacing-l;
  }
}
