@import 'styles/settings.scss';

.c-header {
  &__title {
    width: fit-content;

    font-family: $font-family;
    font-weight: $fw-bold-light;
    font-size: $fs-medium-plus;
    color: $color-dark-gray;
    letter-spacing: 0.01rem;
    text-align: center;

    &--margin-bottom {
      margin-bottom: 30px;
      letter-spacing: $letter-spacing-xl;
    }
  }

  &__subtitle {
    display: flex;
    justify-content: center;

    margin-bottom: $spacing-l;

    font-family: $font-family;
    font-weight: $fw-semi-bold;
    font-size: 14px;
    line-height: 22px;
    color: $color-dark-gray;
    text-align: center;
  }
}
