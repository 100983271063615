@import 'styles/settings.scss';

.c-new-worklfow-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-l;

  margin-top: $spacing-l;
  width: 100%;

  &__input-wrap {
    width: 100%;
  }

  &__error-message {
    margin: $spacing-5xs;
    height: $spacing-l;
    font-size: $fs-xxsmall;

    color: $color-red;
  }
}
