@import 'styles/settings.scss';

.c-section-actions {
  display: flex;
  gap: $spacing-2xs;
  align-items: center;

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    height: $spacing-2xl;
    width: $spacing-2xl;

    cursor: pointer;

    border: 1px solid $color-light-gray-new-3;
    border-radius: 50%;

    transition: all $transition-1;

    &:hover {
      border-color: $color-primary;

      svg {
        path {
          fill: $color-primary;
        }
      }
    }

    &--delete {
      &:hover {
        border-color: $color-error;

        svg {
          path {
            fill: $color-error;
          }
        }
      }
    }
  }

  &__icon {
    height: 14px;
    width: 14px;

    path {
      fill: $color-light-gray-new-3;
    }
  }
}
