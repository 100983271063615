@import 'styles/settings.scss';

.c-edit-button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 23px;
  width: 24px;
  cursor: pointer;

  path {
    &:not(:last-child) {
      fill: $color-light-gray-new-3;
    }

    &:last-child {
      stroke: $color-light-gray-new-3;
    }
  }

  &:not(:disabled) {
    &:hover {
      path {
        &:not(:last-child) {
          fill: $color-dark-gray-new-2;
        }

        &:last-child {
          stroke: $color-primary;
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;

    path {
      &:not(:last-child) {
        fill: $color-light-gray-new-4;
      }

      &:last-child {
        stroke: $color-light-gray-new-4;
      }
    }
  }
}
