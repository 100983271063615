@import 'styles/settings.scss';

.c-side-content {
  display: flex;
  flex-direction: column;
  gap: 40px;

  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);

  &__main-image {
    width: 800px;

    @include breakpoint(desktop) {
      width: 900px;
    }

    @include breakpoint(desktop-xl) {
      width: 900px;
    }

    @include breakpoint(desktop-xxl) {
      width: 1100px;
    }

    @include breakpoint(desktop-xxxl) {
      width: 1500px;
    }
  }

  &__logo-container {
    max-width: fit-content;
  }

  &__logo {
    width: 260px;
    margin-bottom: 8px;
    g {
      g :first-child {
        path {
          fill: $color-logo;
        }
      }
    }
  }

  &__logo-text {
    font-size: $fs-small;
    font-weight: $fw-semi-bold;
    text-align: right;
  }

  &__main-text {
    font-size: 1.75rem;
    font-weight: $fw-bold-light;
    color: $color-dark-gray-new;

    @include breakpoint(desktop) {
      max-width: 40%;
    }

    @include breakpoint(tablet) {
      max-width: 40%;
    }
  }
}
