@import 'styles/settings.scss';

.c-phase {
  padding: $spacing-xl;

  border-radius: $radius-m;
  background-color: $color-white;
  height: 100%;

  &__header {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-xl;
  }

  &__header-left {
    display: flex;
    align-items: center;
    gap: $spacing-l;
    width: 100%;
  }

  &__icon {
    width: 16px;
    height: 16px;
    cursor: grab;

    &:focus {
      outline: none;
    }
  }

  &__input-container {
    width: 100%;
    padding: $spacing-xs 0;

    &--date,
    &--description {
      border-bottom: 0.5px solid $color-gray;

      display: flex;
      justify-content: space-between;
      gap: $spacing-l;
    }
  }

  &__description {
    font-size: $fs-small;
    font-weight: $fw-bold-light;

    border-radius: $radius-s;
    border: 0.5px solid $color-gray;
    min-height: 60px;
    width: 100%;
    padding: $spacing-xs;

    &::placeholder {
      color: $color-gray-3;
      font-weight: $fw-medium;
    }
  }

  &__date-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-2xs;
  }

  &__date-label {
    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-light-gray-new-3;
  }

  &__date {
    width: 50%;
    padding: $spacing-2xs;

    font-size: $fs-small;
    font-weight: $fw-bold-light;
    color: $color-dark-gray;
    background-color: $color-white;
  }

  &__list-header {
    font-weight: $fw-bold-light;
    font-size: $fs-small;
    line-height: $spacing-l;
    color: $color-gray-2;

    padding: $spacing-l 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__members-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  &__more-icon {
    font-size: $fs-xsmall;
    font-weight: $fw-bold-light;

    border: 1px solid $color-primary;
    border-radius: 50%;
    width: 28px;
    height: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
