@import 'styles/settings.scss';

.c-user-info {
  height: calc(100% - 135px);
  width: 25%;
  box-shadow: $box-shadow-3;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  overflow-y: auto;
  @include custom-scrollbar;
  padding: $spacing-2xl;
  padding-right: 0;
  position: absolute;
  top: calc($toolbar-height + $spacing-l);
  right: $spacing-xl;
  text-align: center;
  z-index: 2;

  &--closable {
    top: 0;
    right: 0;
    height: 100%;
  }

  &:not(.c-user-info--closable) {
    border-radius: 15px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  }

  &--top {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: $color-primary-light;
      z-index: -1;
    }
  }

  &__full-profile {
    height: 15%;
    margin-bottom: $spacing-l;
    display: flex;
    justify-content: left;
    align-items: flex-end;
  }

  &__icon {
    width: 13px;
    height: 13px;
    path {
      fill: $color-black !important;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(119, 119, 119, 0.7);
    z-index: 1;
  }

  &__close-icon {
    position: absolute;
    right: 0px;
    top: $spacing-2xl;
    z-index: 1;
    cursor: pointer;

    ellipse {
      transition: fill $transition-1;
    }
    &:hover {
      cursor: pointer;

      ellipse {
        fill: $color-dark-gray;
      }
    }
  }

  &__divider {
    margin: 10px 0;
  }

  &__section-container {
    padding-bottom: 27px;
  }
  &__sections-container {
    height: 70%;
    padding-top: $spacing-4xl;

    display: flex;
    flex-direction: column;

    @media (max-height: 1000px) {
      padding-top: 0;
    }
  }

  &__info-section {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
    &--m-top {
      margin-top: 30px;
    }
  }

  &__data {
    font-weight: $fw-base;
    color: $color-dark-gray-new-2;
    font-size: $fs-base-l;
    line-height: 16px;

    &--reporting-to {
      padding-top: 5px !important;
    }
    &--email {
      &:hover {
        color: $primary-color;
      }
    }
    &--secondary {
      font-size: $fs-base-s;
      color: $color-gray-7;
    }

    &--role {
      font-size: $fs-xsmall;
      font-weight: $fw-medium;
      color: $color-gray;
    }

    &--bio {
      padding-top: $spacing-5xs;
      display: block;
      word-break: break-all;
    }

    &--missing {
      font-weight: $fw-semi-bold;
      color: $color-gray;
      text-transform: uppercase;
      font-size: $fs-base-l;
      line-height: 16px;
    }

    &--job-title {
      font-size: $fs-base-xl;
      font-weight: $fw-medium;
      color: $color-black;
    }

    &--name {
      font-size: $fs-xlarge;
      color: $color-black;
      font-weight: $fw-extra-bold;
      margin-top: 25px;
    }

    &--clickable {
      cursor: pointer;

      &:hover:not(.c-user-info__data--email) {
        color: $color-secondary;
      }
    }
  }

  &__data-row {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;

    &--space {
      display: flex;
      justify-content: flex-start;
      gap: 7px;
      align-items: center;
      position: relative;
      max-height: 16px;
    }

    &--bio {
      display: block;
      text-align: left;
    }
  }

  &__data-label {
    font-size: $fs-base-l;
    color: $color-black;
    font-weight: $fw-bold-light;
    line-height: 16px;
    text-align: left;
  }

  &__button-wrap {
    position: relative;
  }

  &__joined {
    margin: 40px 0;
    font-size: $fs-small;
    font-weight: $fw-bold;
    color: #a38c35;
    line-height: 16px;
    text-align: left;
    background-color: #f9efce;
    width: fit-content;
    padding: 7px 14px;
    border-radius: 20px;
  }

  &__social-media-icons {
    align-items: center;
    display: flex;
    gap: $spacing-5xs;
  }

  &__social-media-icon {
    max-height: 23px;
    max-width: 23px;

    &:hover {
      cursor: pointer;

      rect {
        fill: $color-primary;
      }
    }
  }

  &__reports {
    display: flex;
    flex-direction: column;
    gap: $spacing-5xs;
    padding-top: 27px;
    overflow-y: visible;
    min-width: none;

    span:not(.c-user-info__reports--label) {
      font-weight: $fw-base;
      color: $color-black;
      font-size: $fs-base-l;
      line-height: $fs-base;
      text-align: left;
    }

    &--label {
      margin-top: 40px;
      margin-bottom: 20px;
      color: $color-black;
      font-weight: $fw-bold-light;
      text-align: left;
    }

    @media (max-height: 1000px) {
      padding-bottom: 0;
    }
  }

  &__reports-container {
    padding: $spacing-xl 0;
    display: flex;
    gap: $spacing-l;

    overflow-y: auto;
    @include custom-scrollbar;

    &--no-data {
      flex-direction: column;
    }
  }

  &__reports-item {
    cursor: pointer;

    div {
      gap: $spacing-xs;
    }

    img {
      max-height: 28px;
      max-width: 28px;

      border-radius: 50%;
    }
  }
}
