@import 'styles/settings.scss';

.c-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;

  padding: 32px 35px;

  background-color: $color-white;
  border-radius: 42px;

  &--large {
    padding: $spacing-4xl 0;
  }

  &--xl {
    padding: $spacing-5xl $spacing-4xl;
  }

  &__form-content {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1368px) {
      height: 90%;
      width: 100%;
    }
  }

  &__select {
    height: $spacing-4xl;

    font-family: $font-family;
    font-size: $fs-medium;
    color: $color-dark-gray !important;

    &--invalid {
      outline: none;
      border: 1px solid $color-red;
    }
  }

  &__checkbox-container {
    margin-top: $spacing-l;

    display: flex;
    align-self: flex-start;
    align-items: center;

    color: $color-gray-2;
  }

  &__checkbox-label {
    text-decoration: underline;
    font-family: $font-family;
    font-style: normal;
    font-size: $fs-base;
    font-weight: $fw-base;
  }

  &__checkbox {
    width: 20px;
    height: 20px;

    margin-right: $spacing-5xs;
  }

  &__button-container {
    width: 40%;

    margin: $spacing-4xl;
  }

  &__option-link {
    font-family: $font-family;
    font-weight: $fw-semi-bold;
    color: $color-gray-2;

    &:hover {
      cursor: pointer;
    }

    &:visited {
      color: $color-gray-2;
    }
  }

  &__button-container {
    width: 100%;
    margin: 20px 0 0 0;
  }

  &__dates-container {
    width: 100%;
    align-items: center;

    display: flex;
    flex-direction: column;
    gap: $spacing-xs;

    margin: $spacing-xs 0;
  }

  &__title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;

    margin: 0 auto;
    gap: $spacing-4xs;

    @include breakpoint(phone) {
      width: 100%;
    }

    @include breakpoint(desktop) {
      width: 100%;
    }
  }

  &__info {
    margin-bottom: $spacing-7xl;
    font-size: $fs-base-xl;
    text-align: center;
  }
}
