@import 'styles/settings.scss';

.c-section-task {
  display: flex;
  justify-content: space-between;
  gap: $spacing-2xs;

  padding: $spacing-xs $spacing-3xs $spacing-xs 0;
  margin-left: $spacing-xs;

  align-items: center;

  border-bottom: 1px solid $color-light-gray-new-1;

  &__name {
    width: 70%;

    display: flex;
    align-items: center;
    gap: $spacing-5xs;

    font-size: $fs-xsmall;
    color: $color-dark-gray-new-2;
    font-weight: $fw-bold-light;

    &--hidden {
      font-size: $fs-small;
      color: $color-gray-3;
      font-weight: $fw-base;
    }
  }

  &__actions {
    display: flex;
    gap: $spacing-2xs;
  }

  &__action-icon {
    height: 18px;

    path {
      fill: $color-gray-3;
    }

    &:hover {
      cursor: pointer;

      path {
        fill: $color-secondary;
      }
    }

    &--disabled {
      pointer-events: none;
      path {
        fill: $color-gray;
      }
    }
  }

  &__draggable-icon {
    &:hover {
      cursor: grab;
      path {
        fill: $color-secondary;
      }
    }
    &:active {
      cursor: grabbing;
      path {
        fill: $color-secondary;
      }
    }
    outline: none;
    align-items: center;

    &--disabled {
      pointer-events: none;
      rect {
        stroke: $color-gray;
      }
    }
  }
}
