@import 'styles/settings.scss';

.c-modal-content {
  width: 100%;

  &__image {
    width: 59px;
    height: 59px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__title {
    color: $color-gray-7;
    font-size: $fs-base;
    font-weight: $fw-bold-light;
    margin-bottom: $spacing-4xs;
  }

  &__date {
    color: $color-dark-gray;
    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;
    margin-bottom: $spacing-4xs;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: $spacing-xs;
    padding: $spacing-l 0;
  }

  &__bottom {
    padding-top: $spacing-l;
    width: 100%;
  }
}
