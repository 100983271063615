@import 'styles/settings.scss';

.c-tasks {
  display: flex;
  flex-direction: column;

  height: 100%;

  &__empty-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: $spacing-l;
    height: 100%;

    border-radius: $spacing-l;
    background-color: $color-white;

    &--title {
      color: $color-dark-gray;
      font-size: $fs-medium;
      font-weight: $fw-bold-light;
    }

    &--message {
      width: 50%;

      text-align: center;
      line-height: $spacing-xl;

      color: $color-gray-2;
      font-size: $fs-base;
      font-weight: $fw-base;
    }
  }
}
