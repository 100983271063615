@import 'styles/settings.scss';

.c-equipment-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-s;
  width: 100%;

  &__multiple-inputs {
    display: flex;
    gap: $spacing-l;

    align-items: center;
    justify-content: center;

    &--checkbox {
      margin: $spacing-2xs 0 $spacing-l;

      span {
        font-size: $fs-small;
        font-weight: $fw-semi-bold;
      }
    }
  }
}
