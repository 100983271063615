@import 'styles/settings.scss';

.c-project-user-list {
  display: flex;
  align-items: center;
  position: relative;

  &__item {
    &:not(:last-child) {
      margin-right: -$spacing-xs;
    }

    &--more {
      font-size: $fs-xxsmall;
      font-weight: $fw-bold-light;
      color: $color-white;

      border-radius: 50%;
      width: 26px;
      height: 26px;
      background-color: $color-primary;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__tooltip-item {
    &:not(:last-child) {
      margin-bottom: $spacing-xs;
    }
  }
}
