@import 'styles/settings.scss';

.c-sidebar {
  width: 15%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-dark-gray-new;
  color: $color-light-gray;
  transition: 0.8s;
  overflow-y: auto;
  overflow-x: hidden;

  &__lower-nav {
    width: 100%;
    height: 60px;
    background-color: $color-dark-gray-new-1;

    display: flex;
    align-items: center;

    &__logo-image {
      width: 25px;
      height: 25px;
      object-fit: cover;
      animation: 1s ease-in-out 0s 1 fadeIn;

      &--large {
        width: 66px;
        margin-left: $spacing-5xl;
        animation: 1s ease-in-out 0s 1 fadeIn;
      }
    }
  }

  &__upper-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: $spacing-xl $spacing-xl $spacing-xl $spacing-5xl;
    border-bottom: 2.5px solid $color-dark-gray-new-1;
  }

  &__header-image {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }

  &__header-title {
    color: $color-white;
    font-weight: $fw-bold;
    font-size: $fs-base-l;
    margin-left: $spacing-s;
  }

  &__actions {
    padding: $spacing-2xs $spacing-5xs $spacing-2xs $spacing-xl;
    border-bottom: 2.5px solid $color-dark-gray-new-1;
  }

  &__items {
    padding: $spacing-2xs $spacing-5xs $spacing-2xs $spacing-xl;
    background-color: $color-dark-gray-new;
    flex: 1;
  }

  &--collapsed {
    width: 70px;
    overflow: visible;

    .c-sidebar__nav {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: $spacing-l 0;
      width: 100%;
    }
    .c-sidebar__lower-nav {
      display: flex;
      justify-content: center;
    }
    .c-sidebar__item-container {
      padding: $spacing-l;
    }
    .c-sidebar__header {
      padding: $spacing-xl 0;
      justify-content: center;
    }
    .c-sidebar__header-image {
      width: 35px;
      height: 35px;
    }
    .c-sidebar__lower-nav__logo-image {
      width: 21px;
      height: 18px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
