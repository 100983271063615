@import 'styles/settings.scss';

.c-location-item {
  &__container {
    padding: $spacing-s 0;
    display: flex;
    justify-content: space-between;
    gap: 10%;

    &-column {
      flex: 1;
    }
  }

  &__text {
    font-weight: $fw-base;
    font-size: $fs-small;
    line-height: 16px;

    color: $color-gray-2;

    &--title {
      font-size: $fs-xsmall;
      color: $color-dark-gray-new-2;
      font-weight: $fw-bold-light;
    }
  }

  &__label {
    font-size: $fs-xxsmall;
    opacity: 0.7;
  }
}
