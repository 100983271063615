@import 'styles/settings.scss';

.c-tab {
  font-size: $fs-xxsmall;
  font-weight: $fw-semi-bold;
  padding: $spacing-2xs $spacing-l;
  border: 1px solid #dbdbdb;
  border-radius: $radius-s;
  cursor: pointer;

  &:hover {
    border-color: $color-secondary;
  }

  &--active {
    color: $color-white;
    border-color: $color-secondary;
    background-color: $color-secondary;
  }
}
