@import 'styles/settings.scss';

.c-project-user-list-item {
  display: flex;
  align-items: center;

  &__image {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__text {
    margin-left: $spacing-xs;
  }
}
