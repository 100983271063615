@import 'styles/settings.scss';

.c-user-item {
  padding: 0.813rem $spacing-l;
  display: grid;
  grid-template-columns: 30% 20% 20% 15% 15%;
  cursor: pointer;
  position: relative;

  &:hover,
  &--highlighted {
    border-radius: $radius-s;
    background-color: $color-light-gray-new-1;
  }

  &__wrap {
    margin: 0 $spacing-2xs 0 $spacing-xl;
    border-bottom: 1px solid $color-gray-5;
  }

  &__data-col {
    display: flex;
    align-items: center;
    padding-right: $spacing-l;
    font-size: $fs-small;

    &--avatar {
      margin-right: $spacing-2xs;
    }

    &--secondary {
      color: $color-gray-2;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      line-height: 1;
      font-weight: $fw-medium;
      font-size: $fs-xxsmall-plus;
      overflow: hidden;
    }

    &--link {
      &:hover {
        cursor: pointer;
        color: $color-secondary;
      }
    }

    &--projects {
      max-height: 48px;
      padding: $spacing-5xs $spacing-2xs;

      position: relative;

      display: flex;
      align-items: center;
      gap: $spacing-2xs;
      flex-wrap: wrap;
    }

    &--project {
      padding: $spacing-4xs $spacing-2xs;

      font-size: $fs-xsmall;
      font-weight: $fw-semi-bold;
      color: #77529e;

      background-color: #ddd4e7;
      border-radius: $radius-s;

      &:hover {
        &:not(.c-user-item__data-col--last-project) {
          background-color: #d0c4de;
        }
      }
    }

    &--primary {
      font-size: $fs-base-s;
      font-weight: $fw-base;
      color: $color-black;
    }

    &--missing-data {
      color: $color-gray;
    }

    &--centered {
      justify-content: center;

      .c-user-item__data-col--location {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-3xs;
      }
    }

    &--button {
      justify-content: center;
    }

    &--location-data {
      text-align: center;
    }
  }

  &__button-icon {
    height: 15px;
    width: 15px;
  }

  @include breakpoint(touch) {
    display: block;

    &__data-col {
      margin-bottom: $spacing-2xs;
      &--avatar {
        margin-bottom: $spacing-l;
      }
      &--centered {
        margin-bottom: 0;
        justify-content: flex-end;
      }
    }
  }
}
