@import 'styles/settings.scss';

.c-section {
  display: flex;
  flex-direction: column;

  padding: $spacing-xs $spacing-xl;

  border-radius: $radius-s;

  font-size: $fs-xsmall;

  background: $color-white;

  &__wrapper {
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  &__sections-holder {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-l;
  }

  &--scrollable {
    max-height: 45vh;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-4xl;
    min-height: $spacing-4xl;
    margin-bottom: $spacing-s;
    padding-right: $spacing-2xs;

    &--warning {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: $color-red;
    }

    &__title-and-add {
      display: flex;
      gap: $spacing-2xs;
      align-items: center;

      button {
        margin-top: 2px;
      }
    }
  }

  &__header-item-top {
    display: flex;
    align-items: flex-start;
    margin-top: $spacing-5xs;
  }

  &__header-item-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-2xs;
  }

  &__header-icon {
    path {
      stroke-width: 0;
      fill: $color-secondary;
    }
  }

  &__header-icon-wrap {
    margin-top: $spacing-3xs;

    height: $spacing-5xl;
    width: $spacing-5xl;

    border: 1px solid $color-secondary;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header-heading {
    font-size: $fs-small;
    margin: $spacing-5xs 0 $spacing-2xs;
    color: $color-gray-7;
    font-weight: $fw-semi-bold;
  }

  &__header-actions {
    margin-left: $spacing-2xs;
  }

  &__title {
    color: $color-gray-7;
    font-size: $fs-base;
    font-weight: $fw-bold-light;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__section-content-container {
    padding-top: $spacing-s;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &--columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10%;
    }
  }

  &__content-column {
    display: flex;
    flex-direction: column;
    width: 50%;

    &--full-width {
      width: 100%;
    }
  }

  &__no-content-data-container {
    min-height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__no-content-data-message {
    font-size: $fs-xsmall;
    font-weight: $fw-semi-bold;
    color: $color-gray;
  }

  &__offboarding {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-xl;

    label {
      color: $color-gray-2;
      font-size: $fs-small;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    gap: $spacing-3xs;
  }

  &__social-item {
    display: grid;
    grid-template-columns: 22% 78%;
    align-items: center;

    height: 43px;

    &-label {
      font-size: $fs-xsmall;
      color: $color-gray-10;
      font-weight: $fw-medium;
    }

    &--icon {
      svg {
        height: 28px;
        width: 28px;
      }

      margin-left: $spacing-xs;

      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;
    }

    &-links {
      display: flex;
      flex-direction: row;
    }
  }

  &__section-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:not(last-child) {
      border-bottom: 1px solid $color-light-gray-new-1;
    }
  }

  &__input-container {
    padding: $spacing-5xs 0;
    width: 100%;

    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__input-label {
    display: flex;
    align-items: center;
  }

  &__input-label-span {
    font-size: $fs-xsmall;
    color: $color-gray-10;
    font-weight: $fw-medium;

    &--required {
      &::after {
        content: '*';
      }
    }
  }

  &__label-icon {
    margin-right: $spacing-s;

    svg {
      height: 16px;
      width: 16px;
    }

    path {
      height: $spacing-l;
      width: $spacing-l;
      fill: $color-primary;
    }

    &--contacts {
      path {
        stroke: $color-primary;
        fill: none !important;
      }
    }
  }

  &__input-file-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-2xs;
  }

  input[type='file'] {
    display: none;
  }

  &__input-file-label {
    border: 1px solid $color-gray-9;
    padding: $spacing-3xs $spacing-s;
    border-radius: $radius-xs;
    text-align: right;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: $fs-xsmall;
    color: $color-dark-gray-new-2;
    font-weight: $fw-bold-light;

    &:hover {
      background-color: $color-gray-5;
    }
  }

  &__upload-input {
    width: 100%;
    display: flex;
    flex: 1;
  }

  &__info {
    color: $color-gray-7;
    font-size: $fs-xsmall;
    font-weight: $fw-base;
  }

  &__content-warning-icon {
    justify-self: flex-end;
    margin-right: $spacing-s;
    min-height: 2.063rem;

    path {
      fill: $color-secondary;
    }
  }
}
